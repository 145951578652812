import React from 'react';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

// ikony
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleDoubleRight } from "react-icons/fa";

// ikony
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";

//addon do mobilni verze
import MenuMobile from "../../komponenty/navbar-mobile/menu.js";


//styles DESKTOP -----------------------------------------------------------
import '../../styles/desktop/firmy/1920x1080.css'; //FULL HD
import '../../styles/desktop/firmy/1366x768px.css'; //DRUHE MONITORY
import '../../styles/desktop/firmy/2560x1440px.css'; //2K MONITORY


//styles TELEFON -----------------------------------------------------------
import '../../styles/mobile/firmy/360x640px.css'; //*TELEFONY


function Firmy() {
  return (
    <div>

      {/* mobile sekce */}
      <div className='mobile-vypnuti'>

          <div id="menu-mobile-main">
            <MenuMobile />
          </div>

{/* navbar */}
        <div id='cely-navbar' className='cely-navbar'>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1'></img>

          <div className='navar-pozadi'>
            <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
            <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
          </div>
      
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2'></img>

          <div id="menu-mobile-externi">
            <MenuMobile />
          </div>

        </div>
{/* konec navbaru */}

<div className='firmy-mobile-pozadi-1'>



<h1 className='firmy-h1'>Firmy</h1>
<p className='ubytovani-pod-h1'>Zajistíme firemní akce, školení i teambuilding</p>
  
<p className='ubytovani-main-p-text'><b>Přemýšlíte, kde uspořádat příští firemní poradu?</b><br></br><br></br> Nabízíme vám kombinaci pracovního prostředí s možností večerní zábavy. U nás nemusíte dodržovat noční klid! Užijte si večer naplno a pokračujte až do brzkých ranních hodin – nerušeně a bez starostí. Vytvoříme vám prostor, kde se můžete uvolnit, bavit se a posilovat týmového ducha v atmosféře, která trvá, dokud budete chtít. Věřte nám organizaci a zažijte nezapomenutelnou firemní akci bez omezení!</p>



<img src='./img/party2.jpg' className='firmy-prvni-img'></img>




<ul className='ubytovani-ul-1'>
  <li className='ubytovani-li-1'><b>Flexibilní prostory:</b> <br></br>Kapacita až 170 osob, z toho 100 v restauraci (80 v hlavním prostoru, 20 v salonku s variabilním umístěním stolů). Terasa s kapacitou až 70 osob, s pevně umístěnými stoly pro 2–6 osob. Možnost posezení pod markýzou i při dešti.</li>
    <br></br>
  <li className='ubytovani-li-1'><b>Prezentační technika pro jeden salonek:</b> <br></br>(dataprojektor, plátno, flipchart, ozvučení) </li>
    <br></br>
  <li className='ubytovani-li-1'><b>Velký venkovní prostor</b> <br></br>Ideální pro pořádání sportovních a volnočasových aktivit, který je travnatý a rovinatý.</li>
    <br></br>
  <li className='ubytovani-li-1'><b>Kryté venkovní pódium</b> <br></br>Vybavené s dostatečným prostorem pro kapelu nebo pořádání představení</li>
    <br></br>
  <li className='ubytovani-li-1'><b>Možnost zajištění venkovního tanečního parketu</b></li>
    <br></br>
  <li className='ubytovani-li-1'><b>Všechny prostory penzionu pouze pro Vaši společnost</b></li>
    <br></br>
  <li className='ubytovani-li-1'><b>Bohatý catering</b> <br></br>Připravený z klasických českých pokrmů až po gastro speciality</li>
    <br></br>
  <li className='ubytovani-li-1'><b>Možnost nápojových rautů </b><br></br>(formou rozlévaných nápojů ve džbánech nebo ve skle)</li>
    <br></br>
  <li className='ubytovani-li-1'><b>Zajištění večerního programu na míru od profesionálů:</b><br></br>Hudební zážitek – DJ, tematické hudební skupiny (cimbálovka, kapely a další)  
  <br></br>Outdoorové aktivity: lidský fotbálek, šipky, týmové soutěže, únikové hry, paintball, skákací hrady a další. Anebo můžete využít okolních turistických cílů (Troják, Tesák, Sv. Hostýn, zoo, hrad Lukov a další)  
  <br></br>Večerní show: taneční vystoupení, bubenická show, módní přehlídky, tematicky zaměřený program (20. Léta, casino, atd.), degustace</li>
    <br></br>
  <li className='ubytovani-li-1'><b>Zajištění ubytování </b><br></br>Nově zrekonstruovaných třílůžkových pokojích až pro 25 osob</li>
    <br></br>
  <li className='ubytovani-li-1'><b>Zajištění vozu ve směru Zlín, Vsetín </b><br></br></li>
    <br></br>
  <li className='ubytovani-li-1'><b>Parkování na pozemku penzionu</b><br></br></li>
    <br></br>
  <li className='ubytovani-li-1'><b>Akceptujeme vlastně dovezenou slivovici</b><br></br></li>
    zdarma při objednávce nad 50 osob
  </ul>




<img src='./img/hospudka-pod-rablinu-main-foto.jpg' className='firmy-prvni-img'></img>
<img src='./img/aktualni/interor2.jpg' className='firmy-druhy-img'></img>
<img src='./img/party.jpg' className='firmy-treti-img'></img>

<img src='./img/venkovni-grillovani.jpg' className='firmy-ctvrty-img'></img>












</div>


<h1 className='hospudka-pod-rablinu-kontaktni-informace'>Kontaktní informace</h1>
      <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Telefon</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>E-maily</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>info@hospudkapodrablinu.cz</h3>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>ubytovani@hospudkapodrablinu.cz</h3>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Sociální sítě</h1>


      <div className="socialni-site-odkazy">
        <a target='blank' href="#"><FaInstagram /></a>
        <a target='blank' href="#"><FaFacebook /></a>
        <a target='blank' href="#"><FaYoutube /></a>
        <a target='blank' href="#"><FaGooglePlus /></a>
      </div>



      <div className="jidelni-lisktek-obrazek-container-1">
        <img src='./img/jidelni-listek/salat.jpg' className='jidelni-lisktek-obrazek-1' />
        <img src='./img/jidelni-listek/kolacky-1.jpg' className='jidelni-lisktek-obrazek-2' />
        <img src='./img/jidelni-listek/maso-1.jpg' className='jidelni-lisktek-obrazek-3' />
      </div>

      <div className="jidelni-lisktek-obrazek-container-2">
        <img src='./img/jidelni-listek/bagety-1.jpg' className='jidelni-lisktek-obrazek-1' />
        <img src='./img/jidelni-listek/maso-stul-1.jpg' className='jidelni-lisktek-obrazek-2' />
        <img src='./img/jidelni-listek/maso-2.jpg' className='jidelni-lisktek-obrazek-3' />
      </div>



      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6'></img>

      <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>

























</div>


{/* KONEC MOBILE SEKCE */}





{/* desktop sekce */}

<div className='desktop-vypnuti'>

<h3 className='desktop-homepage-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
    <h4 className='desktop-homepage-h4-main-nadpis'>Ta pravá chuť Valašska</h4>

    <h3 className='desktop-homepage-h3-main-telefon'>+420 608 608 101</h3>

    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>

    
    <div className='desktop-aktuality-body'>

      <h3 className='desktop-aktuality-text-1'>FIREMNÍ AKCE</h3>

    </div>


    <p className='desktop-firmy-text-1'><b>Přemýšlíte, kde uspořádat příští firemní poradu?</b> Nabízíme vám kombinaci pracovního prostředí s možností večerní zábavy.<br></br>
      U nás nemusíte dodržovat noční klid! Užijte si večer naplno a pokračujte až do brzkých ranních hodin – nerušeně a bez starostí.<br></br>
      Vytvoříme vám prostor, kde se můžete uvolnit, bavit se a posilovat týmového ducha v atmosféře, která trvá, dokud budete chtít.<br></br><br></br>
      <u>Věřte nám organizaci a zažijte nezapomenutelnou firemní akci bez omezení!</u></p>


    <p className='desktop-firmy-2'>
    <FaAngleRight /> Flexibilní prostory: Kapacita až 170 osob, z toho 100 v restauraci (80 v hlavním prostoru, 20 v salonku s variabilním umístěním stolů).<br></br>
    <FaAngleRight /> Terasa s kapacitou až 70 osob, s pevně umístěnými stoly pro 2–6 osob. Možnost posezení pod markýzou i při dešti.<br></br>
    <FaAngleRight /> Prezentační technika pro jeden salonek (dataprojektor, plátno, flipchart, ozvučení)<br></br>
    <FaAngleRight /> Velký venkovní prostor pro pořádání sportovních a volnočasových aktivit, který je travnatý a rovinatý.<br></br>
    <FaAngleRight /> Kryté venkovní pódium s dostatečným prostorem pro kapelu nebo pořádání představení<br></br>
    <FaAngleRight /> Možnost zajištění venkovního tanečního parketu<br></br>
    <FaAngleRight /> Všechny prostory penzionu pouze pro Vaši společnost<br></br>
    <FaAngleRight /> Bohatý catering od klasických českých pokrmů až po gastro speciality<br></br>
    <FaAngleRight /> Možnost nápojových rautů (formou rozlévaných nápojů ve džbánech nebo ve skle)<br></br>
    <FaAngleRight /> Zajištění večerního programu na míru od profesionálů:<br></br>
       <FaAngleDoubleRight /> Hudební zážitek – DJ, tematické hudební skupiny (cimbálovka, kapely a další)<br></br>
       <FaAngleDoubleRight /> Outdoorové aktivity: lidský fotbálek, šipky, týmové soutěže, únikové hry, paintball, skákací hrady a další. Anebo můžete využít okolních turistických cílů.<br></br>
       <FaAngleDoubleRight /> Večerní show: taneční vystoupení, bubenická show, módní přehlídky, tematicky zaměřený program (20. Léta, casino, atd.), degustace<br></br>
    <FaAngleRight /> Zajištění ubytování v nově zrekonstruovaných třílůžkových pokojích až pro 25 osob<br></br>
    <FaAngleRight /> Zajištění vozu ve směru Zlín, Vsetín<br></br>
    <FaAngleRight /> Parkování u penzionu<br></br>
    <FaAngleRight /> Akceptujeme vlastní slivovici, kterou si přivezete<br></br><br></br>
    <i>*zdarma při objednávce nad 50 osob</i></p>



    <div className='desktop-firmy-rozsireni-1'></div>

    <p className='desktop-firmy-nabidka-1'>Vzorová nabídka</p>

    <p className='desktop-firmy-prestavka-na-kavu'>Přestávka na kávu, <i>varianta 1</i></p>
    <p className='desktop-firmy-prestavka-na-kavu-verze-2'><FaAngleRight /> Káva, čaj, voda ve džbánech, džusy ve džbánech</p>
    <p className='desktop-firmy-prestavka-na-kavu-verze-3'><FaAngleRight /> Sladké pečivo ks/os (koláček, buchta, závin...)</p>
    <p className='desktop-firmy-prestavka-na-kavu-verze-4'><FaAngleRight /> Slané pečivo ks/os (jednohubky, chlebíček, minisendvič, šneci…)</p>
    <p className='desktop-firmy-prestavka-na-kavu-verze-5'><FaAngleRight /> Ovoce (hroznové víno, pomeranče...)</p>

    <p className='desktop-firmy-prestavka-na-kavu-verze-6'>Cena: 100 Kč/os</p>

{/* ------------------------------ */}

    <p className='desktop-firmy-prestavka2-na-kavu'>Přestávka na kávu, <i>varianta 2</i></p>
    <p className='desktop-firmy-prestavka2-na-kavu-verze-2'><FaAngleRight /> Káva, čaj, nealko nápoje ve skle (voda, džus, coca cola)</p>
    <p className='desktop-firmy-prestavka2-na-kavu-verze-3'><FaAngleRight /> Sladký moučník ks/os (tiramisu, tartaletka, cheesecake…)</p>
    <p className='desktop-firmy-prestavka2-na-kavu-verze-4'><FaAngleRight /> Slané pečivo ks/os (kanapky s uzeným lososem, obložený croissant..)</p>
    <p className='desktop-firmy-prestavka2-na-kavu-verze-5'><FaAngleRight /> Ovoce (hroznové víno, jahody, maliny, meloun žlutý…)</p>

    <p className='desktop-firmy-prestavka2-na-kavu-verze-6'>Cena: 140 Kč/os</p>

{/* ------------------------------ */}

    <p className='desktop-firmy-prestavka3-na-kavu'>Oběd, <i>varianta 1</i></p>
    <p className='desktop-firmy-prestavka3-na-kavu-verze-2'><FaAngleRight /> <b>Polévka naservírovaná v mísách:</b> hovězí vývar, kyselice, nebo česnečka</p>
    <p className='desktop-firmy-prestavka3-na-kavu-verze-3'><FaAngleRight /> <b>Hlavní chod:</b></p>
    <p className='desktop-firmy-prestavka3-na-kavu-verze-4'>1. Vepřová panenka na lůžku z fazolových lusků, demi glace, hranolky</p>
    <p className='desktop-firmy-prestavka3-na-kavu-verze-5'>2. Salát s grilovaným hermelínem</p>
    <p className='desktop-firmy-prestavka3-na-kavu-verze-7'>3. Těstoviny s kousky kuřecího masa, smetanou, tymiánem a pravým hříbky, sypaným parmazánem</p>


    <p className='desktop-firmy-prestavka3-na-kavu-verze-6'>Cena: 180 Kč/os</p>

{/* ------------------------------ */}

    <p className='desktop-firmy-prestavka4-na-kavu'>Večerní raut, <i>varianta 1</i></p>
    <p className='desktop-firmy-prestavka4-na-kavu-verze-3'><FaAngleRight /> Salámové mísy</p>
    <p className='desktop-firmy-prestavka4-na-kavu-verze-4'><FaAngleRight /> Zeleninové saláty</p>
    <p className='desktop-firmy-prestavka4-na-kavu-verze-5'><FaAngleRight /> Smažené variace řízků</p>
    <p className='desktop-firmy-prestavka4-na-kavu-verze-6'><FaAngleRight /> Hovězí tatarák a topinky</p>
    <p className='desktop-firmy-prestavka4-na-kavu-verze-7'><FaAngleRight /> Hovězí guláš</p>
    <p className='desktop-firmy-prestavka4-na-kavu-verze-8'><FaAngleRight /> Bramboráčky</p>
    <p className='desktop-firmy-prestavka4-na-kavu-verze-9'><FaAngleRight /> Grilované sýry</p>
    <p className='desktop-firmy-prestavka4-na-kavu-verze-10'><FaAngleRight /> Kuřecí paličky</p>
    <p className='desktop-firmy-prestavka4-na-kavu-verze-11'><FaAngleRight /> Pečené žebra</p>
    <p className='desktop-firmy-prestavka4-na-kavu-verze-12'><FaAngleRight /> Sýrové mísy</p>
    <p className='desktop-firmy-prestavka4-na-kavu-verze-13'><FaAngleRight /> Sladký raut - frgály nebo krémové moučníky</p>
    <p className='desktop-firmy-prestavka4-na-kavu-verze-14'><FaAngleRight /> Doplňky k jídlu - variace pečiva, nakládané okurky, papriky, olivy, křen, dresinky</p>

    <p className='desktop-firmy-prestavka4-na-kavu-verze-15'>Cena: 800 Kč/os</p>


{/* ------------------------------ */}



<img src='./img/fotka-interier-2.jpg' className='desktop-firmy-fotka-dolu-1'></img>




      

    <div className='desktop-kontakt-zmensovaci-div'></div>
    <div className='desktop-firmy-footer'><Footer /></div>
    
    </div>

    </div>

    
    //KONEC DESKTOP SEKCE
    
  );
}

export default Firmy;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { CSSTransition } from 'react-transition-group';
import './menu.css';

//IKONY
import { FaRegNewspaper } from "react-icons/fa"; //AKTUALITY
import { AiFillHome } from "react-icons/ai"; //DOMŮ
import { GiForkKnifeSpoon } from "react-icons/gi"; //DENNÍ MENU
import { MdRestaurantMenu } from "react-icons/md"; //JÍDELNÍ LÍSTEK
import { FaBed } from "react-icons/fa"; //UBYTOVÁNÍ
import { AiOutlinePhone } from "react-icons/ai"; //KONTAKT
import { FaImages } from "react-icons/fa"; //GALERIE / FOTOGALERIE
import { FaCalendarCheck } from "react-icons/fa"; //REZERVACE
import { FaBuilding } from "react-icons/fa"; //FIRMY
import { GiDiamondRing } from "react-icons/gi"; //SVATBY

function MenuMobile() {
  const [bool, setBool] = useState(0);
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const toggleBool = () => {
    setBool(bool === 0 ? 1 : 0);
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  useEffect(() => {
    document.body.style.overflow = bool === 1 ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [bool]);

  return (
    <div>
      <IoMenu id='hlavni-ikona-menu-mobile' className="ikona" onClick={toggleBool} />

      <CSSTransition
        in={bool === 1}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <div className="inside">
          <div className="links">
            <Link className="social-specialist-mobile-cyan" to="/"> <AiFillHome className='mobile-react-native-icon-1' /> Domů</Link>
            <Link to="/aktuality">      <FaRegNewspaper className='mobile-react-native-icon-1' />    Aktuality</Link>
            <Link to="/jidelni-listek"> <MdRestaurantMenu className='mobile-react-native-icon-1' />  Jídelní lístek</Link>
            <Link to="/denni-nabidka">  <GiForkKnifeSpoon className='mobile-react-native-icon-1' />  Denní nabídka</Link>
            <Link to="/svatby">         <GiDiamondRing className='mobile-react-native-icon-1' />     Svatby</Link>
            <Link to="/firmy">          <FaBuilding className='mobile-react-native-icon-1' />        Firmy</Link>

            <div className="submenu-container">
              <Link to="#" onClick={toggleSubmenu}>
                <FaBed className='mobile-react-native-icon-1' /> Ubytování 
                <span className={`caret ${submenuOpen ? "open" : ""}`}></span>
              </Link>

              <CSSTransition
                in={submenuOpen}
                timeout={300}
                classNames="submenu-fade"
                unmountOnExit
              >
                <div className="submenu">
                  <Link to="/ubytovani">Ubytování</Link>
                  <Link to="/balicky">Cenové balíčky</Link>
                </div>
              </CSSTransition>
            </div>

            <Link to="/fotogalerie"> <FaImages className='mobile-react-native-icon-1' />         Galerie</Link>
            <Link to="/rezervace">  <FaCalendarCheck className='mobile-react-native-icon-1' />   Rezervace</Link>
            <Link to="/kontakt"> <AiOutlinePhone className='mobile-react-native-icon-1' />       Kontakt</Link>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default MenuMobile;

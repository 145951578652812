import React, { useState, useEffect, useContext, useRef } from 'react';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


import MenuMobile from "../../komponenty/navbar-mobile/menu.js";
// ikony
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";


import 'animate.css';


//styles DESKTOP -----------------------------------------------------------
import '../../styles/desktop/balicky/1920x1080.css'; //FULL HD
import '../../styles/desktop/balicky/1366x768px.css'; //DRUHE MONITORY
import '../../styles/desktop/balicky/2560x1440px.css'; //2K MONITORY


//styles TELEFON -----------------------------------------------------------
import '../../styles/mobile/balicky/360x640px.css'; //*TELEFONY


function Balicky() {
  return (
    <div>

{/* mobile sekce */}
      <div className='mobile-vypnuti'>

          <div id="menu-mobile-main">
            <MenuMobile />
          </div>

{/* navbar */}
<div id='cely-navbar' className='cely-navbar'>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1'></img>

          <div className='navar-pozadi'>
            <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
            <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
          </div>
      
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2'></img>

          <div id="menu-mobile-externi">
            <MenuMobile />
          </div>
  </div>
{/* konec navbaru */}


<h1 className='mobile-balicky-nazev-balicku-main'>🪺Velikonoční pobyt!🐣</h1>
<h4 className='mobile-balicky-nazev-balicku-second'>Užijte si jedinečné jarní prázdniny v malebném prostředí Hostýnských vrchů!</h4>


<img src='./img/balicek-prvni-foto.jpg' className='mobile-balicky-obrazek-pod-second'></img>

<h3 className='mobile-balicky-nazev-balicku-third'>Prožijte nezapomenutelné Velikonoce s rodinou na našem penzionu uprostřed klidných lesů na Kašavě! <br /><br />
Připravili jsme pro vás jedinečný pobyt plný zábavy a tradic – děti se mohou těšit na animační program, venkovní hřiště, jízdy na koni
a mini zoo, zatímco dospělí si vychutnají speciální velikonoční menu z naší domácí kuchyně. <br /><br />
Přijďte si užít sváteční pohodu v přírodě, kde si každý člen rodiny najde to své!</h3>



<h1 className='mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku'>OBSAH POBYTOVÉHO BALÍČKU</h1>


<h1 className='mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku2'>Ubytování ve třílůžkových pokojích s možností přistýlky</h1>
<h1 className='mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku3'>Snídaně formou bufetu</h1>
<h1 className='mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku3'>Animační program</h1>
<h1 className='mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku3'>Mini ZOO</h1>
<h1 className='mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku3'>Vstup na venkovní hřiště, skákací hrad (dle počasí)</h1>
<h1 className='mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku3'>Úschova kol</h1>
<h1 className='mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku3'>Velikonoční pomlázka pro děti</h1>
<h1 className='mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku3'>Uvítací drink</h1>



<h1 className='mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku'>CENOVÁ NABÍDKA</h1>





<div className="mobile-balicky-table">
    <div className="mobile-balicky-row">
        <div className="mobile-balicky-label">2 dospělé osoby a 2 děti do 12 let</div>
        <div className="mobile-balicky-price"><u>2 noci: </u>12 000 Kč</div>
        <div className="mobile-balicky-price"><u>3 noci: </u>14 250 Kč</div>
        <div className="mobile-balicky-price"><u>4 noci: </u>16 700 Kč</div>
        <br /><br />
    </div>
    <div className="mobile-balicky-row">
        <div className="mobile-balicky-label">2 dospělé osoby a 1 dítě do 12 let</div>
        <div className="mobile-balicky-price"><u>2 noci: </u>10 400 Kč</div>
        <div className="mobile-balicky-price"><u>3 noci: </u>12 250 Kč</div>
        <div className="mobile-balicky-price"><u>4 noci: </u>14 350 Kč</div>
        <br /><br />
    </div>
    <div className="mobile-balicky-row">
        <div className="mobile-balicky-label">1 dospělá osoba a 1 dítě do 12 let</div>
        <div className="mobile-balicky-price"><u>2 noci: </u>6 850 Kč</div>
        <div className="mobile-balicky-price"><u>3 noci: </u>8 050 Kč</div>
        <div className="mobile-balicky-price"><u>4 noci: </u>9 430 Kč</div>
        <br /><br />
    </div>
</div>













<h1 className='mobile-balicky-nazev-balicku-prijde-sem'>Přijďte si užít aktivní dovolenou s celou rodinou a načerpejte energii v klidu přírody.</h1>






      
<div className='mobile-pozadi-bacliky'></div>























 


{/*  ------------------------------------------------------------------------------ */}


<img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1'></img>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace'>Kontaktní informace</h1>
      <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Telefon</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>E-maily</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>info@hospudkapodrablinu.cz</h3>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>ubytovani@hospudkapodrablinu.cz</h3>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Sociální sítě</h1>


      <div className="socialni-site-odkazy">
        <a target='blank' href="#"><FaInstagram /></a>
        <a target='blank' href="#"><FaFacebook /></a>
        <a target='blank' href="#"><FaYoutube /></a>
        <a target='blank' href="#"><FaGooglePlus /></a>
      </div>


      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6'></img>

      <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>

    
  </div>











{/* ------------------------------DESKTOP--------------------------- */}



<div className='desktop-vypnuti'>

<h3 className='desktop-homepage-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
    <h4 className='desktop-homepage-h4-main-nadpis'>Ta pravá chuť Valašska</h4>

    <h3 className='desktop-homepage-h3-main-telefon'>+420 608 608 101</h3>

      
   
    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>


{/* KONEC NAVBARU --- KONEC NAVBARU --- KONEC NAVBARU --- KONEC NAVBARU --- KONEC NAVBARU --- KONEC NAVBARU*/}



<div className='desktop-balicky-body'>

<h3 className='desktop-balicky-text-1'>BALÍČKY</h3>

</div>


  <img src='./img/hospudka-pod-rablinu-main-foto.jpg' className='desktop-balicky-fotka-main-1'></img>


  <h1 className='desktop-balicky-1-text-main'>🪺 Velikonoční pobyt 🐣</h1>           {/* CMS */}

  <h4 className='desktop-balicky-text-second-1'>                                                     {/* CMS */}
  Přijďte si užít Velikonoce v srdci přírody! 🏡<br></br>
  </h4>    
  
  <h4 className='desktop-balicky-text-second-2'>                                                     {/* CMS */}
   Čeká na Vás animační program pro děti, mini zoo a spousta zábavy. Pro dospělé pak speciální velikonoční menu plné tradičních chutí. Přijďte si užít aktivní dovolenou s celou rodinou a načerpejte energii v klidu přírody. 🍽️
  {/* <b>Přijďte si užít aktivní dovolenou s celou rodinou a načerpejte energii v klidu přírody.</b> */}
  </h4>         



  <h1 className='desktop-balicky-obsah-balicku'>Obsah pobytového balíčku:</h1>                           {/* CMS */}
  
  <h4 className='desktop-balicky-obsah-balicku-2'>                                                         {/* CMS */}
  <FaAngleRight /> Ubytování ve třílůžkových pokojích s možností přistýlky<br></br>
  <FaAngleRight /> Snídaně formou bufetu<br></br>
  <FaAngleRight /> Animační program<br></br>
  <FaAngleRight /> Mini ZOO<br></br>
  <FaAngleRight /> Vstup na venkovní hřiště, skákací hrad (dle počasí)<br></br>
  <FaAngleRight /> Úschova kol<br></br>
  <FaAngleRight /> Velikonoční pomlázka pro děti<br></br>
  </h4> 


  <img src='./img/main-fotka-venku.jpg' className='desktop-balicky-fotka-main-2'></img>
  <img src='./img/hlavni-interier.jpg' className='desktop-balicky-fotka-main-3'></img>



  <h1 className='desktop-balicky-cena-balicku'>Cenová nabídka:</h1>                           {/* CMS */}



  <table className='desktop-balicky-ceny-tabulka'>
        <thead>
            <tr>
                <th>Počet osob</th>
                <th></th>
                <th></th>
                <th>4 noci</th>
                <th>3 noci</th>
                <th>2 noci</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>2 dospělé osoby a 2 děti do 12 let</td>
                <td></td>
                <td></td>
                <td>16 700 Kč</td>
                <td>14 250 Kč</td>
                <td>12 000 Kč</td>
            </tr>
            <tr>
                <td>2 dospělé osoby a 1 dítě do 12 let</td>
                <td></td>
                <td></td>
                <td>14 350 Kč</td>
                <td>12 250 Kč</td>
                <td>10 400 Kč</td>
            </tr>
            <tr>
                <td>1 dospělá osoba a 1 dítě do 12 let</td>
                <td></td>
                <td></td>
                <td>9 430 Kč</td>
                <td>8 050 Kč</td>
                <td>6 850 Kč</td>
            </tr>
        </tbody>
    </table>


  <p className='desktop-balicky-text-pod-tabulku'>
    V ceně jsou zahrnuty všechny služby uvedené v pobytovém balíčku, včetně městských poplatků.
    Nabízíme možnost ubytování i pro vaše čtyřnohé přátele. U nás jsou domácí mazlíčci vítáni jak v pokojích, tak v restauraci!
    Poplatek za domácího mazlíčka: 100 Kč/noc.
  </p>








  <div className='desktop-balicky-footer'><Footer /></div>





</div>


</div>    
  );
}

export default Balicky;

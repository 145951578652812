import React from 'react';
import "./desktop_napojovy_listek.css";
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";

// ikony


function DenniNabidka() {
  return (
    <div>

<div className='desktop-vypnuti'>

<h3 className='desktop-aktuality-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
    <h4 className='desktop-aktuality-h4-main-nadpis'>Ta pravá chuť Valašska</h4>

    <h3 className='desktop-aktuality-h3-main-telefon'>+420 608 608 101</h3>

      
   
    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>

{/* -------------------------------------------------------- */}

  <div className='desktop-aktuality-body'>

    <h3 className='desktop-aktuality-text-1'>NÁPOJOVÝ LÍSTEK</h3>
    <img src='./img/obrazek-hpr-2.PNG' className='desktop-obrazek-hospudka-pod-rablinu-2'></img>

  </div>
  


  <p className='desktop-nl-main-1'>Nealkoholické nápoje</p>

    <p className='desktop-nealko-drink-1'><b>0,5l/0,3l </b><text className='desktop-napojovy-listek-mezera-1'>.........................................</text>	Kofola čepovaná</p>
    <div className='desktop-nealko-drink-podtrzeni-1'></div>

    <p className='desktop-nealko-drink-2'><b>0,25l </b><text className='desktop-napojovy-listek-mezera-1'>...............................................</text>	RC Cola, Vinea</p>
    <div className='desktop-nealko-drink-podtrzeni-2'></div>

    <p className='desktop-nealko-drink-3'><b>0,33l	</b><text className='desktop-napojovy-listek-mezera-1'>...............................................</text>	Rajec </p>
    <div className='desktop-nealko-drink-podtrzeni-3'></div>

    <p className='desktop-nealko-drink-4'><b>0,25l </b><text className='desktop-napojovy-listek-mezera-1'>...............................................</text> Chito tonic</p>
    <div className='desktop-nealko-drink-podtrzeni-4'></div>

{/* druha cast */}

    <p className='desktop-nealko3-drink-1'><b>0,2l </b> <text className='desktop-napojovy-listek-mezera-1'>.................................................</text>	Džus Rauch</p>
    <div className='desktop-nealko3-drink-podtrzeni-1'></div>

    <p className='desktop-nealko3-drink-2'><b>1l </b><text className='desktop-napojovy-listek-mezera-1'>....................................................</text>	Voda s citrónem</p>
    <div className='desktop-nealko3-drink-podtrzeni-2'></div>

    <p className='desktop-nealko3-drink-3'><b>0,25l	</b><text className='desktop-napojovy-listek-mezera-1'>...............................................</text>	Ledový čaj</p>
    <div className='desktop-nealko3-drink-podtrzeni-3'></div>

    <p className='desktop-nealko3-drink-4'><b>0,5l </b><text className='desktop-napojovy-listek-mezera-1'>.................................................</text> Džus s vodou</p>
    <div className='desktop-nealko3-drink-podtrzeni-4'></div>


{/* zmensi */}
    <div className='desktop-napojovy-listek-zmenseni-1'></div>


    <p className='desktop-nl-main-2'>Káva & Teplé nápoje</p>

    <p className='desktop-kava-1'><b>7g </b><text className='desktop-napojovy-listek-mezera-1'>......................................</text>	Turecká káva s mlékem</p>
    <div className='desktop-nealko2-drink-podtrzeni-1'></div>

    <p className='desktop-kava-2'><b>7g </b><text className='desktop-napojovy-listek-mezera-1'>......................................</text>	Espresso s mlékem</p>
    <div className='desktop-nealko2-drink-podtrzeni-2'></div>

    <p className='desktop-kava-3'><b>7g	</b><text className='desktop-napojovy-listek-mezera-1'>.......................................</text>	Vídeňská káva</p>
    <div className='desktop-nealko2-drink-podtrzeni-3'></div>

    <p className='desktop-kava-4'><b>7g </b><text className='desktop-napojovy-listek-mezera-1'>.......................................</text> Alžírská káva</p>
    <div className='desktop-nealko2-drink-podtrzeni-4'></div>

    <p className='desktop-kava-5'><b>7g </b><text className='desktop-napojovy-listek-mezera-1'>.......................................</text> Capuccino</p>
    <div className='desktop-nealko2-drink-podtrzeni-5'></div>

    <p className='desktop-kava-6'><b>7g </b><text className='desktop-napojovy-listek-mezera-1'>........................................</text> Latte</p>
    <div className='desktop-nealko2-drink-podtrzeni-6'></div>

{/* ----------------------------------------------------------------------------------- */}


    <p className='desktop-kava2-1'><b>1ks </b><text className='desktop-napojovy-listek-mezera-1'>...........................</text>	Čaj s citrónem</p>
    <div className='desktop-nealko4-drink-podtrzeni-1'></div>

    <p className='desktop-kava2-2'><b>0,5l </b><text className='desktop-napojovy-listek-mezera-1'>...........................</text>	Čaj z čerstvé máty a zázvoru</p>
    <div className='desktop-nealko4-drink-podtrzeni-2'></div>

    <p className='desktop-kava2-3'><b>0,2l	</b><text className='desktop-napojovy-listek-mezera-1'>...........................</text>	Svařené víno</p>
    <div className='desktop-nealko4-drink-podtrzeni-3'></div>

    <p className='desktop-kava2-4'><b>0,2l </b><text className='desktop-napojovy-listek-mezera-1'>...........................</text> Grog</p>
    <div className='desktop-nealko4-drink-podtrzeni-4'></div>

    <p className='desktop-kava2-5'><b>0,2l </b><text className='desktop-napojovy-listek-mezera-1'>...........................</text> Horký džus</p>
    <div className='desktop-nealko4-drink-podtrzeni-5'></div>


{/* ----------------------------------------------------------------------------------- */}

    <div className='desktop-pivo-zvetseni-1'></div>

    <p className='desktop-nl-main-3'>Piva & Nealkoholická piva</p>


    <p className='pivo-1'><b>0,5l/0,3l </b><text className='desktop-napojovy-listek-mezera-1'>..................................</text>	Pivo 11˚</p>
    <div className='desktop-nealko5-drink-podtrzeni-1'></div>

    <p className='pivo-2'><b>0,5l </b><text className='desktop-napojovy-listek-mezera-1'>.........................................</text>	Pilsner Urquell láhev</p>
    <div className='desktop-nealko5-drink-podtrzeni-2'></div>

    <p className='pivo-3'><b>0,5l	</b><text className='desktop-napojovy-listek-mezera-1'>.........................................</text>	Nealkoholické pivo</p>
    <div className='desktop-nealko5-drink-podtrzeni-3'></div>

    <p className='pivo-4'><b>0,5l </b><text className='desktop-napojovy-listek-mezera-1'>..................................................</text> Ochucený Birell</p>
    <div className='desktop-nealko5-drink-podtrzeni-4'></div>

    <p className='pivo-5'><b>0,5l </b><text className='desktop-napojovy-listek-mezera-1'>..................................................</text> Pivo plech</p>
    <div className='desktop-nealko5-drink-podtrzeni-5'></div>


{/* ----------------------------------------------------------------------------------- */}

    <div className='desktop-pivo-zvetseni-1'></div>


    <p className='desktop-nl-main-4'>Víno & aperativy</p>


    <p className='vino-1'><b>0,2l </b><text className='desktop-napojovy-listek-mezera-1'>....................................................</text> Víno rozlévané</p>
    <div className='desktop-nealko6-drink-podtrzeni-1'></div>

    <p className='vino-2'><b>0,75l </b><text className='desktop-napojovy-listek-mezera-1'>..................................................</text> Bohemia sekt</p>
    <div className='desktop-nealko6-drink-podtrzeni-2'></div>

    <p className='vino-3'><b>0,1l </b><text className='desktop-napojovy-listek-mezera-1'>................................................</text> Martini, Cinzano</p>
    <div className='desktop-nealko6-drink-podtrzeni-3'></div>

    <p className='vino-4'><b>0,1l </b><text className='desktop-napojovy-listek-mezera-1'>................................................</text> Metropolt</p>
    <div className='desktop-nealko6-drink-podtrzeni-4'></div>




    <div className='desktop-alko-zvetseni-1'></div>

    <p className='desktop-nl-main-5'>Alkoholické nápoje</p>


    <p className='alkohol-1'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>.........................</text> Griotka, vaječný likér</p>
    <div className='desktop-nealko7-drink-podtrzeni-1'></div>

    <p className='alkohol-2'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>.........................</text> Carolans, Malibu, Finlandia</p>
    <div className='desktop-nealko7-drink-podtrzeni-2'></div>

    <p className='alkohol-3'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>.........................</text> Fernet Stock, Citrus</p>
    <div className='desktop-nealko7-drink-podtrzeni-3'></div>

    <p className='alkohol-4'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>.........................</text> Božkov Vodka, Rum</p>
    <div className='desktop-nealko7-drink-podtrzeni-4'></div>

    <p className='alkohol-5'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>.........................</text> Božkov Republica</p>
    <div className='desktop-nealko7-drink-podtrzeni-5'></div>

    <p className='alkohol-6'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>.........................</text> Rum Stroh 80%</p>
    <div className='desktop-nealko7-drink-podtrzeni-6'></div>

    <p className='alkohol-7'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>.........................</text> Bacardi, Tequila, Gin</p>
    <div className='desktop-nealko7-drink-podtrzeni-7'></div>

    <p className='alkohol-8'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>.........................</text> Slivovice, Hruškovice</p>
    <div className='desktop-nealko7-drink-podtrzeni-8'></div>

    <p className='alkohol-9'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>.........................</text> Stará myslivecká</p>
    <div className='desktop-nealko7-drink-podtrzeni-9'></div>

{/* ------------------------------------------------------------------------------------------------------------------------------------------------------- */}

    <p className='alkohol2-1'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>........................</text> Metaxa 5*</p>
    <div className='desktop-nealko8-drink-podtrzeni-1'></div>

    <p className='alkohol2-2'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>........................</text> Four Roses, Jameson</p>
    <div className='desktop-nealko8-drink-podtrzeni-2'></div>

    <p className='alkohol2-3'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>........................</text> Tullamore, Johnie Walker</p>
    <div className='desktop-nealko8-drink-podtrzeni-3'></div>

    <p className='alkohol2-4'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>........................</text> Jack Daniel´s originál, honey</p>
    <div className='desktop-nealko8-drink-podtrzeni-4'></div>

    <p className='alkohol2-5'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>........................</text> Chivas Regal 12 y.o</p>
    <div className='desktop-nealko8-drink-podtrzeni-5'></div>

    <p className='alkohol2-6'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>........................</text> Jagermaister</p>
    <div className='desktop-nealko8-drink-podtrzeni-6'></div>

    <p className='alkohol2-7'><b>0,04l </b><text className='desktop-napojovy-listek-mezera-1'>........................</text> Captain Morgan</p>
    <div className='desktop-nealko8-drink-podtrzeni-7'></div>

    <p className='alkohol2-8'><b>0,03l </b><text className='desktop-napojovy-listek-mezera-1'>........................</text> Cuba Libre</p>
    <div className='desktop-nealko8-drink-podtrzeni-8'></div>

    <p className='alkohol2-9'><b>0,03l </b><text className='desktop-napojovy-listek-mezera-1'>........................</text> Aperol Spritz</p>
    <div className='desktop-nealko8-drink-podtrzeni-9'></div>







    <div className='desktop-napojovy-listek-footer'><Footer /></div>

    </div> {/* konec DESKTOP verze */}

    








    </div>
    
  );
}

export default DenniNabidka;

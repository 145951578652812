import React from 'react';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

// ikony
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleDoubleRight } from "react-icons/fa";

// ikony
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";


//addon do mobilni verze
import MenuMobile from "../navbar-mobile/menu.js";

//stylovani / stylesheet
import './rezervace-mobile.css';
import './rezervace-desktop.css';



function Rezervace() {
  return (
    <div>

      {/* mobile sekce */}
      <div className='mobile-vypnuti'>

          <div id="menu-mobile-main">
            <MenuMobile />
          </div>

{/* navbar */}
        <div id='cely-navbar' className='cely-navbar'>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1'></img>

          <div className='navar-pozadi'>
            <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
            <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
          </div>
      
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2'></img>

          <div id="menu-mobile-externi">
            <MenuMobile />
          </div>

        </div>
{/* konec navbaru */}





<div className='rezervace-body-pozadi'>

<img src='./img/obrazek-hpr-2.PNG' className='rezervace-top-img-1'></img>

<h1 className='rezervace-zamluvte-si-termin'>Zamluvte si termín</h1>
<h1 className='rezervace-zamluvte-si-termin-2'>Na pár kliknutí</h1>

{/* <div className="rezervace-div-container-1">
  <div className="rezervace-div-box-1">Rezervace <b>pokoje</b></div>
  <div className="rezervace-div-box-2">Rezervace <b>akcí</b></div>
</div> */}


<Link to="/rezervace-ubytovani">
  <div className="rezervace-div-main-1">Rezervace <b> pokoje</b></div>
</Link>

<Link to="/rezervace-akce">
  <div className="rezervace-div-main-2">Rezervace <b> akcí</b></div>
</Link>

<h1 className='rezervace-podminky-1'>Všeobecné podmínky rezervace</h1>

<p className='rezervace-podminky-text-1'>Rezervaci pokoje je nutné provést <b>minimálně 24 hodin před</b> plánovaným příjezdem. V případě, že rezervace není provedena včas, prosíme o osobní kontakt, telefonické spojení nebo zaslání e-mailu pro dohodnutí alternativních možností.</p>

<p className='rezervace-podminky-text-2'>Pokud máte zájem o rezervaci místa pro vaši firmu či akci, je nutné provést rezervaci <b>minimálně 7 pracovních dní před</b> plánovaným termínem.
V případě pozdní rezervace nás kontaktujte osobně, telefonicky nebo e-mailem, na což se vám odpoví do 24 hodin .</p>


<h1 className='rezervace-footer-text'>© 2020 - 2025 GAMPEIROS s.r.o.</h1>



</div> {/* konec pozadi */}


</div>
{/* KONEC MOBILE SEKCE */}













{/* desktop sekce */}

<div className='desktop-vypnuti'>

  
    <text className='desktop-telefon-top'>+420 608 608 101</text>
    <a href='mailto:info@hospudkapodrablinu.cz' className='desktop-text-top2'>info@hospudkapodrablinu.cz</a>


    <img src='./img/hospudka-pod-rablinu-logo.png' className='desktop-obrazek-logo'></img>

    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>

    
    <div className='desktop-aktuality-body'>

      <h3 className='desktop-aktuality-text-1'>FIRMY</h3>
      <img src='./img/obrazek-hpr-2.PNG' className='desktop-obrazek-hospudka-pod-rablinu-2'></img>

    </div>


    
    <div className='desktop-kontakt-zmensovaci-div'></div>
    <Footer />
    </div>

    </div>
    //KONEC DESKTOP SEKCE
    
  );
}

export default Rezervace;

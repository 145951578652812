import React from 'react';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

// ikony
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";

//addon do mobilni verze
import MenuMobile from "../../komponenty/navbar-mobile/menu.js";

//styles DESKTOP -----------------------------------------------------------
import '../../styles/desktop/kontakt/1920x1080.css'; //FULL HD
import '../../styles/desktop/kontakt/1366x768px.css'; //DRUHE MONITORY
import '../../styles/desktop/kontakt/2560x1440px.css'; //2K MONITORY


//styles TELEFON -----------------------------------------------------------
import '../../styles/mobile/kontakt/360x640px.css'; //*TELEFONY



function Kontakt() {
  return (
    <div>



{/* ZACATEK MOBILNI SEKCE */}

<div className='mobile-vypnuti'>
  <div id="menu-mobile-main">
            <MenuMobile />
          </div>

{/* navbar */}
        <div id='cely-navbar' className='cely-navbar'>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1'></img>

          <div className='navar-pozadi'>
            <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
            <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
          </div>
      
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2'></img>

          <div id="menu-mobile-externi">
            <MenuMobile />
          </div>

        </div>
{/* konec navbaru */}


      <br></br><br></br><br></br><br></br><br></br><br></br>
      


      <h1 className='hospudka-pod-rablinu-kontaktni-informace'>Kontaktní informace</h1>
      <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Telefon</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>E-maily</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>ubytovani@hospudkapodrablinu.cz</h3>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>info@hospudkapodrablinu.cz</h3>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Sociální sítě</h1>


      <div className="socialni-site-odkazy">
        <a target='blank' href="https://www.instagram.com/hospudkapodrablinu/"><FaInstagram /></a>
        <a target='blank' href="https://www.facebook.com/hospudkapodrablinuofficial/?locale=cs_CZ"><FaFacebook /></a>
        <a target='blank' href="#"><FaYoutube /></a>
        <a target='blank' href="https://www.google.com/search?q=hosp%C5%AFdka+pod+rablin%C5%AF&sca_esv=72621ea52693b3f8&rlz=1C1CHBD_csCZ876CZ876&sxsrf=AHTn8zoiqleAAH6kERn3sYunIEYp4iqdYg%3A1739728576724&ei=wCayZ5bmK6nT9u8P0MmH6Q4&hotel_occupancy=&gs_ssp=eJzj4tFP1zc0KsxILjBKMjdgtFI1qDAxNzQ2N7VINEpKSUxNMUuzMqiwMDBPsrA0MjM2TTNKNk2y8BLLyC8uOLo-JTtRoSA_RaEoMSknM-_oegBZ4Bib&oq=hospudka+pod+rablin&gs_lp=Egxnd3Mtd2l6LXNlcnAiE2hvc3B1ZGthIHBvZCByYWJsaW4qAggAMg4QLhiABBjHARiYBRivATIFEAAYgAQyBhAAGBYYHjIGEAAYFhgeMgIQJjIIEAAYgAQYogQyCBAAGIAEGKIEMh0QLhiABBjHARiYBRivARiXBRjcBBjeBBjgBNgBAUjGH1DXCVjTE3ACeAGQAQGYAeIBoAH5BaoBBTMuMi4xuAEByAEA-AEBmAIHoAKzBMICChAAGLADGNYEGEfCAgQQIxgnwgILEC4YgAQYxwEYrwHCAg4QLhiABBjHARiOBRivAZgDAOIDBRIBMSBAiAYBkAYIugYGCAEQARgUkgcDNC4zoAeoQw&sclient=gws-wiz-serp"><FaGooglePlus /></a>
      </div>



      <div className="jidelni-lisktek-obrazek-container-1">
        <img src='./img/jidelni-listek/salat.jpg' className='jidelni-lisktek-obrazek-1' />
        <img src='./img/jidelni-listek/kolacky-1.jpg' className='jidelni-lisktek-obrazek-2' />
        <img src='./img/jidelni-listek/maso-1.jpg' className='jidelni-lisktek-obrazek-3' />
      </div>

      <div className="jidelni-lisktek-obrazek-container-2">
        <img src='./img/jidelni-listek/bagety-1.jpg' className='jidelni-lisktek-obrazek-1' />
        <img src='./img/jidelni-listek/maso-stul-1.jpg' className='jidelni-lisktek-obrazek-2' />
        <img src='./img/jidelni-listek/maso-2.jpg' className='jidelni-lisktek-obrazek-3' />
      </div>



      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6'></img>

      <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>
</div>

{/* KONEC MOBILNI SEKCE */}




{/* ZACATEK MOBILNI SEKCE */}

<div className='desktop-vypnuti'>


{/* -------DESKTOP NAVBAR------------------------------------------------- */}


<h3 className='desktop-homepage-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
    <h4 className='desktop-homepage-h4-main-nadpis'>Ta pravá chuť Valašska</h4>

    <h3 className='desktop-homepage-h3-main-telefon'>+420 608 608 101</h3>

    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>


{/* -------------------------------------------------------- */}

    
    <div className='desktop-aktuality-body'>

      <h3 className='desktop-aktuality-text-1'>KONTAKT</h3>
      <img src='./img/obrazek-hpr-2.PNG' className='desktop-obrazek-hospudka-pod-rablinu-2'></img>

    </div>



      <b className="desktop-kontakt-main-1">Hospůdka pod Rablinů</b>
      <p className="desktop-kontakt-main-2"><b>Adresa : </b>Kašava 302, 763 19</p>
      <p className="desktop-kontakt-main-2-2"><b>GPS : </b>49.297828, 17.768988</p>


      <p className="desktop-kontakt-main-3"><b>Telefon:</b> +420 608 608 101</p>

      <p className="desktop-kontakt-main-4">
        <b>Ubytování : </b>
        <a href='mailto:ubytovani@hospudkapodrablinu.cz'>ubytovani@hospudkapodrablinu.cz</a> 
      </p>

      <p className="desktop-kontakt-main-5">
        <b>Akce : </b>
        <a href='mailto:info@hospudkapodrablinu.cz'>info@hospudkapodrablinu.cz</a>
      </p>
      
      <p className="desktop-kontakt-main-6">Poslední objednávka na jídlo je přijímána <b>Po - St v 15:00</b> a <b>Čt - Ne v 19:00</b>.</p>

      <div className='desktop-white-pozadi-2-kontakt'>

        {/* <h3 className='desktop-aktualne-text-2'>Kde nás najdete</h3> */}
        {/* <img src='./img/obrazek-hpr-2.PNG' className='desktop-obrazek-hospudka-pod-rablinu-3'></img> */}

          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6457.226696882322!2d17.769411!3d49.297946!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4713758a2bdaed6f%3A0x807b892635f2c5b8!2sHosp%C5%AFdka%20Pod%20Rablin%C5%AF!5e1!3m2!1scs!2sus!4v1732654509624!5m2!1scs!2sus" loading="lazy" className='desktop-mapa-footer-1'></iframe>
    
        {/* <p className='desktop-aktualne-last-center-text'>
          <a className='desktop-aktualne-last-center-text-odkaz' href='https://www.google.com/maps?ll=49.29772,17.768977&z=15&t=h&hl=cs&gl=US&mapclient=embed&cid=9258144256197051832' target='blank'>GPS: 49.297828, 17.768988</a>
        </p> */}

      </div>

    <div className='desktop-kontakt-zmensovaci-div'></div>
    </div>


    <div className='desktop-footer-kontakt-1'><Footer /></div>


    </div>

//KONEC DESKTOP SEKCE
    
  );
}

export default Kontakt;

import { useEffect } from 'react';

function RezervaceUbytovani() {
  useEffect(() => {
    window.location.href = "/rezervace-ubytovani.php";
  }, []);
  
  return null;
}

export default RezervaceUbytovani;
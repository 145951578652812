import React, { useState, useEffect, useContext, useRef } from 'react';
import './fotogalerie-desktop.css';
import './fotogalerie-mobile.css';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


import MenuMobile from "../../komponenty/navbar-mobile/menu.js";
// ikony
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";

import 'animate.css';



function HomePage() {
  return (
    <div>

{/* mobile sekce */}
      <div className='mobile-vypnuti'>

          <div id="menu-mobile-main">
            <MenuMobile />
          </div>

{/* navbar */}
        <div id='cely-navbar' className='cely-navbar'>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1'></img>

          <div className='navar-pozadi'>
            <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
            <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
          </div>
      
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2'></img>

          <div id="menu-mobile-externi">
            <MenuMobile />
          </div>

        </div>
{/* konec navbaru */}

      
<div className='fotogalerie-mobile-pozadi-1'>
  <h1 className='jidelni-liskte-main-text-1'>Fotogalerie</h1>


  <div className="jidelni-listek-container">
    <ul className="jidelni-listek-odkazy">
      <li><a href='svatby'>Svatby</a></li>
      <li><a href='mobile-fotogalerie-ubytovani'>Ubytování</a></li>
      <li><a href='mobile-fotogalerie-restaurace'>Restaurace & menu</a></li>

      <li><a href='mobile-fotogalerie-exterier'>Exteriér a okolí</a></li>
      <li><a href='mobile-fotogalerie-nase-speciality'>Naše speciality</a></li>
      <li><a href='mobile-fotogalerie-sladky-bar'>Sladký bar</a></li>
      <li><a href='mobile-fotogalerie-rauty'>Rauty & catering</a></li>
      <li><a href='mobile-fotogalerie-duo-jamaha'>Duo Jamaha</a></li>

    </ul>
  </div>


{/* ---------------------------------------------------------------------------------------  */}

<Link to="/fotogalerie_svatby">

  <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1'></img>

  <div className='fotogalerie-div-pred-fotkou-1'>
    <h1 className='jidelni-liskte-main-text-1'>Svatby</h1>
  </div>

  <img src='./img/svatby.jpg' className='fotogalerie-svatby-1-fotka'></img>

</Link>

{/* ---------------------------------------------------------------------------------------  */}

<Link to="/mobile-fotogalerie-ubytovani">

<img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1'></img>

  <div className='fotogalerie-div-pred-fotkou-1'>
    <h1 className='jidelni-liskte-main-text-1'>Ubytování & pokoje</h1>
  </div>

  <img src='./img/hlavni-interier.jpg' className='fotogalerie-svatby-1-fotka'></img>

</Link>

{/* ---------------------------------------------------------------------------------------  */}

<Link to="/mobile-fotogalerie-restaurace">
    
<img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1'></img>

  <div className='fotogalerie-div-pred-fotkou-1'>
    <h1 className='jidelni-liskte-main-text-1'>Restaurace</h1>
  </div>

  <img src='./img/aktualni/interior1.jpg' className='fotogalerie-svatby-1-fotka'></img>

</Link>

{/* ---------------------------------------------------------------------------------------  */}

<Link to="/mobile-fotogalerie-duo-jamaha">

<img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1'></img>

  <div className='fotogalerie-div-pred-fotkou-1'>
    <h1 className='jidelni-liskte-main-text-1'>Duo Jamaha</h1>
  </div>

  <img src='./img/img-akce-duo-jamaha.jpg' className='fotogalerie-svatby-1-fotka'></img>

</Link>

{/* ---------------------------------------------------------------------------------------  */}

<Link to="/mobile-fotogalerie-exterier">

<img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1'></img>

  <div className='fotogalerie-div-pred-fotkou-1'>
    <h1 className='jidelni-liskte-main-text-1'>Exteriér a okolí</h1>
  </div>

  <img src='./img/main-fotka-venku.jpg' className='fotogalerie-svatby-1-fotka'></img>

</Link>

{/* ---------------------------------------------------------------------------------------  */}

<Link to="/mobile-fotogalerie-nase-speciality">

<img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1'></img>

  <div className='fotogalerie-div-pred-fotkou-1'>
    <h1 className='jidelni-liskte-main-text-1'>Naše speciality</h1>
  </div>

  <img src='./img/img-nase-speciality.jpg' className='fotogalerie-svatby-1-fotka'></img>

</Link>

{/* ---------------------------------------------------------------------------------------  */}

<Link to="/mobile-fotogalerie-sladky-bar">

<img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1'></img>

  <div className='fotogalerie-div-pred-fotkou-1'>
    <h1 className='jidelni-liskte-main-text-1'>Sladký bar</h1>
  </div>

  <img src='./img/img-sladky-bar.jpg' className='fotogalerie-svatby-1-fotka'></img>

</Link>

{/* ---------------------------------------------------------------------------------------  */}

<Link to="/mobile-fotogalerie-raut">

<img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1'></img>

  <div className='fotogalerie-div-pred-fotkou-1'>
    <h1 className='jidelni-liskte-main-text-1'>Rauty a občerstvení</h1>
  </div>

  <img src='./img/img-rauty.jpg' className='fotogalerie-svatby-1-fotka'></img>

</Link>

{/* ---------------------------------------------------------------------------------------  */}



{/* ---------------------------------------------------------------------------------------  */}


<img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1'></img>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace'>Kontaktní informace</h1>
      <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Telefon</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>E-maily</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>info@hospudkapodrablinu.cz</h3>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>ubytovani@hospudkapodrablinu.cz</h3>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Sociální sítě</h1>


      <div className="socialni-site-odkazy">
        <a target='blank' href="https://www.instagram.com/hospudkapodrablinu/"><FaInstagram /></a>
        <a target='blank' href="https://www.facebook.com/hospudkapodrablinuofficial/?locale=cs_CZ"><FaFacebook /></a>
        <a target='blank' href="#"><FaYoutube /></a>
        <a target='blank' href="https://www.google.com/search?q=hospudka+pod+rablinu&sca_esv=c59580ff1a3027c0&sxsrf=AHTn8zr3iqVLNVCKXp_aG8pQm7_RK1Wo4Q%3A1740676956791&ei=XJ_AZ_b-L8n6i-gP3_yN-AQ&hotel_occupancy=&ved=0ahUKEwj2nLb9ruSLAxVJ_QIHHV9-A08Q4dUDCBA&uact=5&oq=hospuddka+pod+arblonu&gs_lp=Egxnd3Mtd2l6LXNlcnAiFWhvc3B1ZGRrYSBwb2QgYXJibG9udTIEEAAYRzIEEAAYRzIEEAAYRzIEEAAYR0jiA1AAWABwAHgCkAEAmAEAoAEAqgEAuAEDyAEAmAIBoAIFmAMA4gMFEgExIECIBgGQBgSSBwExoAcA&sclient=gws-wiz-serp"><FaGooglePlus /></a>
      </div>


      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6'></img>

      <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>

















    
  </div>



      </div>
    </div>
    
  );
}

export default HomePage;

import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";


import "./style.css";

function NavbarDesktop() {
  const [isFixed, setIsFixed] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navbarRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 190) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 190) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
  
      if (activeDropdown) {
        setActiveDropdown(null);
      }
    };
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeDropdown]);
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setActiveDropdown(null);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (dropdown: string, event: React.MouseEvent) => {
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();

    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + rect.width / 2,
    });

    setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
  };

  return (
    <div className="App" ref={navbarRef}>
      <div className={`navbar ${isFixed ? "fixed" : ""}`}>
        <div className="nav-links">
          <Link to="/">DOMŮ</Link>

          <Link to="/aktuality">AKTUALITY</Link>


          <div
            className="dropdown"
            onClick={(event) => toggleDropdown("menu", event)}
          >
            <a>MENU</a>
          </div>

          <div
            className="dropdown"
            onClick={(event) => toggleDropdown("svatby", event)}
          >
            <a>SVATBY</a>
          </div>

          <div
            className="dropdown"
            onClick={(event) => toggleDropdown("firmy", event)}
          >
            <Link to="/firmy">FIRMY</Link>
          </div>

          <div
            className="dropdown"
            onClick={(event) => toggleDropdown("ubytovani", event)}
          >
            <a>UBYTOVÁNÍ</a>
          </div>

          <Link to="/galerie">GALERIE</Link>
          <Link to="/kontakt">KONTAKT</Link>
        </div>
      </div>

      <div
        ref={dropdownRef}
        style={{
          position: "absolute",
          top: dropdownPosition.top,
          left: dropdownPosition.left,
          transform: "translateX(-50%)",
          display: activeDropdown ? "block" : "none",
          zIndex: 50,
        }}
      >
        {activeDropdown === "menu" && (
          <div className="dropdown-container">
            <div className="dropdown-content">
            <Link to="/denni-nabidka">DENNÍ NABÍDKA</Link>
            <Link to="/jidelni-listek">JÍDELNÍ LÍSTEK</Link>
            <Link to="/odpoledni-nabidka">ODPOLEDNÍ NABÍDKA</Link>
            <Link to="/napojovy-listek">NÁPOJOVÝ LÍSTEK</Link>
            </div>
          </div>
        )}

        {activeDropdown === "svatby" && (
          <div className="dropdown-container">
            <div className="dropdown-content">
            <Link to="/svatby-prostory">PROSTORY</Link>
            <Link to="/svatby-hostina">HOSTINA</Link>
            </div>
          </div>
        )}

        

        {activeDropdown === "ubytovani" && (
          <div className="dropdown-container">
            <div className="dropdown-content">
              <a><Link to="/ubytovani">UBYTOVÁNÍ</Link></a>
              <a><Link to="/balicky">BALÍČKY</Link></a>
            </div>
          </div>
        )}
      </div>

      {/* <div id="desktop-navbar-zmenseni" style={{ height: "2200px" }}></div> */}
    </div>
  );
}

export default NavbarDesktop;

import React from 'react';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

// ikony
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleDoubleRight } from "react-icons/fa";

// ikony
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";


//addon do mobilni verze
import MenuMobile from "../../komponenty/navbar-mobile/menu.js";


//styles DESKTOP -----------------------------------------------------------
import '../../styles/desktop/ubytovani/1920x1080.css'; //FULL HD
import '../../styles/desktop/ubytovani/1366x768px.css'; //DRUHE MONITORY
import '../../styles/desktop/ubytovani/2560x1440px.css'; //2K MONITORY


//styles TELEFON -----------------------------------------------------------
import '../../styles/mobile/ubytovani/360x640px.css'; //*TELEFONY



function Ubytovani() {
  return (
    <div>

      {/* mobile sekce */}
      <div className='mobile-vypnuti'>

          <div id="menu-mobile-main">
            <MenuMobile />
          </div>

{/* navbar */}
        <div id='cely-navbar' className='cely-navbar'>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1'></img>

          <div className='navar-pozadi'>
            <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
            <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
          </div>
      
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2'></img>

          <div id="menu-mobile-externi">
            <MenuMobile />
          </div>

        </div>
{/* konec navbaru */}




<div className='ubytovani-body-pozadi'>


<h1 className='ubytovani-h1'>Ubytování</h1>

<p className='ubytovani-pod-h1'>Probuďte se do srdce valašské přírody a jejího okolí</p>
  
<img src='./img/hlavni-interier.jpg' className='ubytovani-prvni-img'></img>
<p className='ubytovani-pod-h1-2'>Pokoj s <b>manželskou postelí</b> a výhledem do okolních lesů</p>


<img src='./img/wifi-logo.PNG' className='ubytovani-info-icon-1'></img>
<img src='./img/tv-logo.PNG' className='ubytovani-info-icon-1'></img>
<img src='./img/parkovani-logo.PNG' className='ubytovani-info-icon-1'></img>
<img src='./img/snidane-logo.PNG' className='ubytovani-info-icon-1'></img>

<p className='ubytovani-radnom-text-2'>K dispozici Vám jsou nově zrekonstruované pokoje s vlastní koupelnou (dvou a více lůžkové).<br></br><br></br> Vhodné pro pobyty s dětmi, novomanžele, firemní akce a nebo jen pro klidný odpočinek od všedních starostí.</p>

<Link to="/rezervace-ubytovani">
  <button className='ubytovani-rezervace-button-1'>Rezervace</button>
</Link>



<img src='./img/ubytovani/ubytovani-1.jpg' className='ubytovani-prvni-img-2'></img>

<img src='./img/ubytovani/ubytovani-3.jpg' className='ubytovani-prvni-img-3'></img>

<img src='./img/ubytovani/ubytovani-4.jpg' className='ubytovani-prvni-img-3'></img>


<Link to="/mobile-fotogalerie-ubytovani">
  <p className='ubytovani-rezervace-vice-info-2-second'>Více fotografií zde</p>
</Link>



























  <h1 className='hospudka-pod-rablinu-kontaktni-informace-svatby-3'>Kontaktní informace</h1>
      <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Telefon</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>E-maily</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>info@hospudkapodrablinu.cz</h3>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>ubytovani@hospudkapodrablinu.cz</h3>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Sociální sítě</h1>


      <div className="socialni-site-odkazy">
        <a target='blank' href="#"><FaInstagram /></a>
        <a target='blank' href="#"><FaFacebook /></a>
        <a target='blank' href="#"><FaYoutube /></a>
        <a target='blank' href="#"><FaGooglePlus /></a>
      </div>


      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6'></img>

      <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>



</div> {/* konec pozadi */}


</div>
{/* KONEC MOBILE SEKCE */}













{/* desktop sekce */}

<div className='desktop-vypnuti'>

  
<h3 className='desktop-aktuality-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
    <h4 className='desktop-aktuality-h4-main-nadpis'>Ta pravá chuť Valašska</h4>

    <h3 className='desktop-aktuality-h3-main-telefon'>+420 608 608 101</h3>

      
   
    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>


{/* KONEC NAVBARU --- KONEC NAVBARU --- KONEC NAVBARU --- KONEC NAVBARU --- KONEC NAVBARU --- KONEC NAVBARU*/}

    
    <div className='desktop-aktuality-body'>

      <h3 className='desktop-aktuality-text-1'>UBYTOVÁNÍ</h3>

    </div>


    <h4 className='desktop-ubytovani-text-second'>                                      {/* CMS */}
      <b>Stylové ubytování uprostřed přírody pro každou příležitost</b><br></br><br></br>
      Hledáte místo, kde si odpočinete a načerpáte novou energii?<br></br>
      Naše nově zrekonstruované pokoje s vlastní koupelnou jsou ideální volbou! Nabízíme třílůžkové pokoje s možností přistýlky, které vám zajistí komfortní ubytování. Některé pokoje nabízejí dokonce balkón, odkud si můžete vychutnat úchvatný výhled do lesa a na malebné Vlčkovské údolí.<br></br>
    </h4>  



    <Link to="/rezervace-ubytovani">
      <button className='desktop-ubytovani-zamluvte-si-termin-tlacitko'>Rezervace ubytování</button>
    </Link>


    <img src="./img/fotka-interier-3.jpg" className='desktop-obrazek-aktualne-pod-rezervaci-1' alt="Hospudka pod Ranlinu okolni priroda" />
    <img src="./img/hlavni-interier.jpg" className='desktop-obrazek-aktualne-pod-rezervaci-2' alt="Restaurace Lesna Zlin" />
    <img src="./img/aktualni/hospudka-pod-rablinu.jpg" className='desktop-obrazek-aktualne-pod-rezervaci-3' alt="Zlin Lesna ubytovani" />


    <h2 className='desktop-ubytovani-text-second-2'>                                      {/* CMS */}
    Ať už plánujete rodinný pobyt, romantický víkend jako novomanželé, firemní akci, nebo si jen chcete dopřát klid a relaxaci, u nás najdete přesně to, co hledáte.
    </h2>    


    <h4 className='desktop-ubytovani-text-second-3'>                                      {/* CMS */}
    Cestujete karavanem?
    </h4> 


    <h4 className='desktop-ubytovani-text-second-4'>                                      {/* CMS */}
    Naše prostorná zahrada a parkoviště vám zajistí pohodlné místo pro zaparkování.
    </h4> 



    <img src="./img/party.jpg" className='desktop-obrazek-aktualne-pod-rezervaci-4' alt="Hospudka pod Ranlinu okolni priroda" />


    <h4 className='desktop-ubytovani-text-second-5'>                                      {/* CMS */}
    Začněte svůj den s energií díky bohaté snídani formou bufetu, kterou pro vás rádi připravíme.
    </h4> 


    <h4 className='desktop-ubytovani-text-second-6'>                                      {/* CMS */}
    Těšíme se na vás v našem penzionu, kde se příroda a pohodlí snoubí v dokonalé harmonii.
    </h4> 



    <img src="./img/fotka-interier-1.jpg" className='desktop-obrazek-aktualne-pod-rezervaci-5' alt="Hospudka pod Ranlinu okolni priroda" />
    <img src="./img/jidelni-listek/bagety-1.jpg" className='desktop-obrazek-aktualne-pod-rezervaci-6' alt="Restaurace Lesna Zlin" />
    <img src="./img/img-sladky-bar.jpg" className='desktop-obrazek-aktualne-pod-rezervaci-7' alt="Zlin Lesna ubytovani" />



    <h4 className='desktop-ubytovani-text-second-7'>                                      {/* CMS */}
    Ceník ubytování a služeb
    </h4> 


    <table className='desktop-ubytovani-tabulka'>
        <thead>
            <tr>
                <th>Ubytovávní</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1 noc</td>
                <td>850 Kč / osoba</td>
            </tr>
            <tr>
                <td>2 a více nocí</td>
                <td>690 Kč / osoba</td>
            </tr>
            <tr>
                <td>Děti do 3 let</td>
                <td>Zdarma (bez nároku na služby)</td>
            </tr>
        </tbody>
    </table>


    
    <table className='desktop-ubytovani-tabulka-2'>
        <thead>
            <tr>
                <th>Snídaně</th>
                <th>Cena</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Dospělá osoba</td>
                <td>170 Kč</td>
            </tr>
            <tr>
                <td>Dítě 3 - 12 let</td>
                <td>100 Kč</td>
            </tr>
        </tbody>
    </table>


















    

    <div className='desktop-ubytovani-footer-main'><Footer /></div>

    </div>

    </div>
    //KONEC DESKTOP SEKCE
    
  );
}

export default Ubytovani;

import { useEffect } from 'react';

function RezervaceAkce() {
  useEffect(() => {
    window.location.href = "/rezervace-akce.php";
  }, []);

  return null;
}

export default RezervaceAkce;
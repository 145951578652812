import React from 'react';
import './footer.css';

// ikony
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { CiMail } from "react-icons/ci";



function Footer() {
  return (
    <div>
    
    <div className='desktop-footer'>

        <p className='desktop-oteviraci-doba'>Otevírací doba</p>

        <div className='desktop-oteviraci-doba-div'></div>

        <div className="desktop-oteviraci-hodiny">
            <p><b>Pondělí</b> ----- 11.00 - 20.00 ( kuchyně do 19.00 )</p>
            <p><b>Úterý</b> ----- 11.00 - 20.00 ( kuchyně do 19.00 )</p>
            <p><b>Středa</b> ----- 11.00 - 20.00 ( kuchyně do 19.00 )</p>
            <p><b>Čtvrtek</b> ----- 11.00 - 20.00 ( kuchyně do 19.00 )</p>
            <p><b>Pátek</b> ----- 11.00 - 21.00 ( kuchyně do 20.00 )</p>
            <p><b>Sobota</b> ----- 11.00 - 21.00 ( kuchyně do 20.00 )</p>
            <p><b>Neděle</b> ----- 11.00 - 20.00 ( kuchyně do 19.00 )</p>
        </div>

        <img src='./img/logo-footer.png' className='desktop-obrazek-footer-logo'></img>
        

        <div className="desktop-vse-ostatni">
            <p className='desktop-footer-telefon'>Telefon</p>
            <br></br>
            <p><b>+420 608 608 101</b></p>
            <br></br>

            <p className='desktop-footer-telefon'>E-maily</p>
            <br></br><br></br>
            <text className='desktop-footer-email'>
            <a className='desktop-footer-email' target='blank' href='mailto:info@hospudkapodrablinu.cz'>info@hospudkapodrablinu.cz</a> <br></br>
            <a className='desktop-footer-email' target='blank' href='mailto:ubytovani@hospudkapodrablinu.cz'>ubytovani@hospudkapodrablinu.cz</a></text>
            <br></br><br></br>

        <div className='desktop-ikona-container'>
            <a className='desktop-ikona' target='blank' href='https://www.facebook.com/hospudkapodrablinuofficial/'>
                <FaFacebook  className='desktop-ikona' />
            </a>

            <a className='desktop-ikona' target='blank' href='https://www.instagram.com/hospudkapodrablinu/'>
                <FaInstagram className='desktop-ikona'/>
            </a>

            <a className='desktop-ikona' target='blank' href='mailto:info@hospudkapodrablinu.cz'>
                <CiMail />
            </a>
        </div>


        </div>
    </div>

    <div className='desktop-copyright-footer-div'>
        <p className='desktop-copyright-footer'>© 2020 - 2025 RABLINA s.r.o.</p>
    </div>


    </div>
  );
}

export default Footer;

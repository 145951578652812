import React from 'react';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import MenuMobile from "../../komponenty/navbar-mobile/menu.js";
// ikony
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa6";


import 'animate.css';


//STYLES
import '../../styles/desktop/svatby-hostina/1366x768px.css';
import '../../styles/desktop/svatby-hostina/1440x900px.css';
import '../../styles/desktop/svatby-hostina/1920x1080.css';


import '../../styles/mobile/svatby-hostina/360x640px.css';



function SvatbyHostina() {
  return (
    <div>
      
      {/* mobile sekce */}
      <div className='mobile-vypnuti'>

        <div id="menu-mobile-main">
          <MenuMobile />
        </div>

      {/* navbar */}
      <div id='cely-navbar' className='cely-navbar'>
        <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1'></img>

        <div className='navar-pozadi'>
          <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
          <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
        </div>

        <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2'></img>

        <div id="menu-mobile-externi">
          <MenuMobile />
        </div>

      </div>

{/* konec navbaru */}

<div className='jidelni-listek-mobile-pozadi-1'>
  <h1 className='jidelni-liskte-main-text-1'>PROSTORY PRO KONÁNÍ SVATEB</h1>




  






  <img src='./img/stuha2.png' className='jidelni-listek-stuha-1'></img>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace'>Kontaktní informace</h1>
      <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Telefon</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>E-maily</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>ubytovanipodrablinuofficial@gmail.com</h3>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>hospudkapodrablinuofficial@seznam.cz</h3>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Sociální sítě</h1>


      <div className="socialni-site-odkazy">
        <a target='blank' href="#"><FaInstagram /></a>
        <a target='blank' href="#"><FaFacebook /></a>
        <a target='blank' href="#"><FaYoutube /></a>
        <a target='blank' href="#"><FaGooglePlus /></a>
      </div>


      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6'></img>

      <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>

  </div> 

</div>



{/* -------------------------------------------------------------------------------- */}




<div className='desktop-vypnuti'>

<h3 className='desktop-aktuality-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
    <h4 className='desktop-aktuality-h4-main-nadpis'>Ta pravá chuť Valašska</h4>

    <h3 className='desktop-aktuality-h3-main-telefon'>+420 608 608 101</h3>

      
   
    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>

{/* -------------------------------------------------------- */}

  <div className='desktop-aktuality-body'>

    <h3 className='desktop-aktuality-text-1'>HOSTINA & CATERING SVATEB</h3>

  </div>



  <img src="./img/rauty/rauty-5.jpg" className="desktop-svatby-prostory-img-main-1" />
  <img src="./img/sladky-bar/sladky-bar-8.jpg" className="desktop-svatby-prostory-img-main-2" />
  <img src="./img/svatby-zradlo-venku.jpg" className="desktop-svatby-prostory-img-main-3" />


    <h4 className='desktop-svatby-prostory-text-1'><b>Pomůžeme Vám uspořádat plnohodnotnou svatební hostinu a to jak u nás v hospůdce tak i cateringem.</b><br /><br /><br /> Zajistíme špičkové svatební menu nebo rozsáhlý obědový raut. Večerní pohoštění pro svatebčany, také formou rautu je samozřejmostí.<br></br> Nebo je libo speciality z grilu, připravované přímo před očima, včetně grilované kýty či selete? A co se týče nápojového pohoštění, upravená nabídka nápojů dle Vašeho přání nebo právě oblíbený trend - samoobslužný alkoholový a nápojový bar určitě také potěší Vaše hosty.</h4>



    <div className='desktop-svatby-prostory-div-1'>
      <p className='desktop-objednavka-rezervace-text-1'>Zamluvte si termín</p>
      <p className='desktop-objednavka-rezervace-text-2'>Na pár kliknutí</p>


      <Link to="/rezervace-ubytovani">
          <button className='desktop-hospudka-pod-rablinu-zamluvte-si-termin-tlacitko'>Rezervace ubytování</button>
      </Link>

      <Link to="/rezervace-akce">
          <button className='desktop-hospudka-pod-rablinu-zamluvte-si-termin-tlacitko-2'>Rezervace akcí</button>
      </Link>


      <br /><br /><br /><br />


    </div>




    <h2 className='desktop-svatby-prostory-text-2'>VENKOVNÍ OBŘAD NENÍ PROBLÉM</h2>



    <img src="./img/img-svatby.jpg" className="desktop-svatby-prostory-img-main-4" />
    <img src="./img/fotka-interier-2.jpg" className="desktop-svatby-prostory-img-main-5" />
    <img src="./img/img-rauty.jpg" className="desktop-svatby-prostory-img-main-6" />



    <Link to="/sladky-bar-fotogalerie" className='desktop-hpr-none-decoration-underline'>
      <p className='desktop-svatby-prostory-text-second-1'>Více z fotografií <FaChevronRight/></p>
    </Link>





<div className='desktop-svatby-prostory-footer'><Footer /></div>









  </div>          {/* konec DESKTOP verze */}
</div>
);
}

export default SvatbyHostina;

import React from 'react';
import "./desktop-odpoledni_nabidka.css";
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";

// ikony


function NapojovyListek() {
  return (
    <div>

<div className='desktop-vypnuti'>

<h3 className='desktop-aktuality-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
    <h4 className='desktop-aktuality-h4-main-nadpis'>Ta pravá chuť Valašska</h4>

    <h3 className='desktop-aktuality-h3-main-telefon'>+420 608 608 101</h3>

      
   
    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>

{/* -------------------------------------------------------- */}



<div className='desktop-aktuality-body'>

<h3 className='desktop-aktuality-text-1'>ODPOLEDNÍ NABÍDKA</h3>
{/* <img src='./img/obrazek-hpr-2.PNG' className='desktop-obrazek-hospudka-pod-rablinu-2'></img> */}

</div>



<div className="desktop-menu-jidelni-listek2">
    <div className="desktop-section-jidelni-listek">
        <h2 className="desktop-title-jidelni-listek">Polévky</h2>
        <p>0,5l Valašská kyselice s pravými hříbky a klobásou</p>
        <p>0,5l Hovězí vývar s kořenovou zeleninou, játrovými knedlíčky a nudlemi</p>
    </div>

    <div className="desktop-section-jidelni-listek">
        <h2 className="desktop-title-jidelni-listek">Hlavní chody</h2>
        <p>200g Špalíček z vepřové panenky</p>
        <p>200g Grilovaný prsní kuřecí steak</p>
        <p>200g Steak z mladého býčka z Kašavského údolí BIO</p>
        <p>200g Filírovaná kuřecí prsíčka s bylinkovým pestem a parmezánem</p>
        <p>180g Kuřecí prsíčka plněná mozzarellou a sušenými rajčaty</p>
        <p>400g Velký Vídeňský řízek (z vepřové panenky pomalu smažený na sádle)</p>
        <p>200g Malý Vídeňský řízek (z vepřové panenky pomalu smažený na sádle)</p>
        <p>150g Smažená kuřecí prsíčka</p>
        <p>100g Smažený hermelín</p>
        <p>120g Smažený eidam</p>
        <p>180g Smažená kuřecí prsíčka plněná tvarůžky a domácí slaninou</p>
        <p>150g Myslivecký guláš, chléb, cibule</p>
        <p>400g Pomalu pečená medová žebírka na černém pivu a chléb</p>
        <p>450g Valašský knedlík (plněný borůvkami se zakysanou smetanou a borůvkovým dipem)</p>
    </div>

    <div className="desktop-section-jidelni-listek">
        <h2 className="desktop-title-jidelni-listek">Pro malý hlad</h2>
        <p>220g Domácí libová tlačenka v patentní sklenici, pečivo</p>
        <p>70g Zvěřinová Paštika, brusinky a pečivo</p>
        <p>120g Tatarák z mladého býčka z Kašavského údolí BIO (4ks topinky)</p>
    </div>

    <div className="desktop-section-jidelni-listek">
        <h2 className="desktop-title-jidelni-listek">Přílohy</h2>
        <p>150g Hranolky</p>
        <p>200g Vařené brambory s máslem</p>
        <p>200g Rozmarýnové brambory</p>
        <p>200g Bramborový salát</p>
        <p>250g Grilovaná zelenina</p>
    </div>
</div>



<div className='desktop-odpoledni-nabidka-footer'><Footer /></div>







      </div>      {/* konec DESKTOP verze  */}
    </div>
    
  );
}

export default NapojovyListek;

import React from 'react';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

// ikony
import { FaAngleRight } from "react-icons/fa6";
import { FaAngleDoubleRight } from "react-icons/fa";

// ikony
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";


//addon do mobilni verze
import MenuMobile from "../../komponenty/navbar-mobile/menu.js";

//stylovani / stylesheet
import './svatby-mobile.css';
import './svatby-desktop.css';



function Svatby() {
  return (
    <div>

      {/* mobile sekce */}
      <div className='mobile-vypnuti'>

          <div id="menu-mobile-main">
            <MenuMobile />
          </div>

{/* navbar */}
        <div id='cely-navbar' className='cely-navbar'>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1'></img>

          <div className='navar-pozadi'>
            <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
            <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
          </div>
      
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2'></img>

          <div id="menu-mobile-externi">
            <MenuMobile />
          </div>

        </div>
{/* konec navbaru */}




<div className='svatby-body-pozadi'>


<img className='svatby-main-fotka-1' src='./img/svatby.jpg'></img>
<img className='svatby-main-fotka-2' src='./img/svatby-venku-1.jpg'></img>
<img className='svatby-main-fotka-3' src='./img/svatba-rablina-exterior-1.jpg'></img>

<p className='svatby-main-text-v-pozadi-1'>Hospůdka pod Rablinů</p>
<p className='svatby-main-text-v-pozadi-2'>SVATEBNÍ UDÁLOSTI</p>

<p className='svatby-main-text-v-pozadi-3'>Naše hospůdka je útulná dřevěná chalupa s rustikalně laděným interiérem. Prostorná zahrada s pódiem, přilehlou terasou a dětským hřištěm jsou ideální pro letní svatební obřady, hudbu a další svatební aktivity. 
  <br></br><br></br>Kapacita našich vnitřních společenských prostor je až 100 osob a zahradní plochy pojmou přibližně dalších 200 lidí. Neváhejte a přijeďte se podívat na naši hospůdku a přilehlé okolí.
</p>


<Link to="/rezervace" className='rezervace-cursor-pointer'>
  <button className='svatby-main-button-1'>Rezervace</button>
</Link>


<img className='svatby-second-fotka-1' src='./img/svatby-venku-1.jpg'></img>
  <p className='svatby-second-text-v-pozadi-1'>Venkovní obřad</p>
  <p className='svatby-second-text-v-pozadi-2'>Naše hospůdka je útulná dřevěná chalupa s rustikalně laděným interiérem. Prostorná zahrada s pódiem, přilehlou terasou a dětským hřištěm jsou ideální pro letní svatební obřady, hudbu a další svatební aktivity.</p>


<img className='svatby-second-fotka-1' src='./img/svatby.jpg'></img>
  <p className='svatby-second-text-v-pozadi-1'>Prostor pro svatební hostiny</p>
  <p className='svatby-second-text-v-pozadi-2'>Prostor pro svatební hostiny s výhledem na nádhernou valašskou přírodu. Užijte si útulné prostředí, domácí speciality podle tradičních receptur a atmosféru, která udělá váš velký den nezapomenutelným.</p>



  <br></br><br></br><br></br><br></br>

  <h1 className='hospudka-pod-rablinu-kontaktni-informace-svatby'>FOTOGRAFIE</h1>
  <h1 className='hospudka-pod-rablinu-kontaktni-informace-svatby-2'>Nezapomenutelné svatební okamžiky u nás</h1>


  <img className='svatby-main-fotka-2' src='./img/svatba-rablina-exterior-1.jpg'></img>
  <img className='svatby-main-fotka-3' src='./img/img-sladky-bar.jpg'></img>


  <img className='svatby-main-fotka-4' src='./img/img-svatby.jpg'></img>
  <img className='svatby-main-fotka-5' src='./img/svatby-venku-1.jpg'></img>

  <img className='svatby-main-fotka-6' src='./img/svatby-zradlo-venku.jpg'></img>
  <img className='svatby-main-fotka-7' src='./img/svatby-vevnitr-1.jpg'></img>




  <h1 className='hospudka-pod-rablinu-kontaktni-informace-svatby-3'>Kontaktní informace</h1>
      <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Telefon</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>E-maily</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>info@hospudkapodrablinu.cz</h3>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>ubytovani@hospudkapodrablinu.cz</h3>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Sociální sítě</h1>


      <div className="socialni-site-odkazy">
        <a target='blank' href="#"><FaInstagram /></a>
        <a target='blank' href="#"><FaFacebook /></a>
        <a target='blank' href="#"><FaYoutube /></a>
        <a target='blank' href="#"><FaGooglePlus /></a>
      </div>


      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6'></img>

      <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>



</div> {/* konec pozadi */}


</div>
{/* KONEC MOBILE SEKCE */}













{/* desktop sekce */}

<div className='desktop-vypnuti'>

  
    <text className='desktop-telefon-top'>+420 608 608 101</text>
    <a href='mailto:info@hospudkapodrablinu.cz' className='desktop-text-top2'>info@hospudkapodrablinu.cz</a>


    <img src='./img/hospudka-pod-rablinu-logo.png' className='desktop-obrazek-logo'></img>

    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>

    
    <div className='desktop-aktuality-body'>

      <h3 className='desktop-aktuality-text-1'>FIRMY</h3>
      <img src='./img/obrazek-hpr-2.PNG' className='desktop-obrazek-hospudka-pod-rablinu-2'></img>

    </div>


    
    <div className='desktop-kontakt-zmensovaci-div'></div>
    <Footer />
    </div>

    </div>
    //KONEC DESKTOP SEKCE
    
  );
}

export default Svatby;

import React from 'react';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import MenuMobile from "../../komponenty/navbar-mobile/menu.js";
// ikony
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa6";
import { FaStar } from "react-icons/fa6";


import 'animate.css';


//styles DESKTOP -----------------------------------------------------------
import '../../styles/desktop/homepage/1920x1080.css'; //FULL HD
import '../../styles/desktop/homepage/1366x768px.css'; //DRUHE MONITORY
import '../../styles/desktop/homepage/2560x1440px.css'; //2K MONITORY


//styles TELEFON -----------------------------------------------------------
import '../../styles/mobile/homepage/360x640px.css'; //*TELEFONY



function HomePage() {
  return (
    <div>

{/* mobile sekce */}
      <div className='mobile-vypnuti'>

          <div id="menu-mobile-main">
            <MenuMobile />
          </div>

{/* navbar */}
        <div id='cely-navbar' className='cely-navbar'>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1'></img>

          <div className='navar-pozadi'>
            <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
            <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
          </div>
      
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2'></img>

          <div id="menu-mobile-externi">
            <MenuMobile />
          </div>

        </div>
{/* konec navbaru */}

      <div className='pozadi-homepage-mobile'>

        <img src='./img/aktualni/hospudka-pod-rablinu.jpg' id='mobile-pozadi-img-main' className='hospudka-pod-rablinu-1'></img>
        <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-3'></img>


      
      <div className='zeleny-prvek-1'>

        <h1 className='hospudka-pod-rablinu-nazev-main'>Hospůdka pod Rablinů</h1>

        <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-main-1'></img>


        <p className='info-o-hospode-text-2'>Náš penzion je zasazen do malebné přírody Hostýnských vrchů, kde na vás čeká pohostinná atmosféra a poctivá domácí kuchyně. V okolí naší hospůdky naleznete minizoo s domácími zvířaty a také dětské hřiště s lanovkou, v letních měsících doplněné navíc o skákací hrad. Kromě běžného provozu v restauraci a na terase zajišťujeme také oslavy narozenin, svatby, firemní akce a večírky.<br></br><br></br>
        
        Zajišťujeme rozvoz denního menu do domácností a firem do blízkého okolí.
        </p>

      
      </div>


      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-4'></img>

      <div className='mobile-pozadi-1-white'>

        <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-2'></img>

        <h1 className='hospudka-pod-rablinu-zamluvte-si-termin'>Zamluvte si termín</h1>
        <p className='hospudka-pod-rablinu-zamluvte-si-termin-2'>Na pár kliknutí</p>

        <Link to="/rezervace">
          <button className='hospudka-pod-rablinu-zamluvte-si-termin-tlacitko'>Rezervace</button>
        </Link>

      </div>

      <img src='./img/hlavni-interier.jpg' className='hospudka-pod-rablinu-pokoj-1'></img>
      <img src='./img/aktualni/interior1.jpg' className='hospudka-pod-rablinu-pokoj-2'></img>

      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-5'></img>

      <div className='mobile-pozadi-2-white'>

        <h1 className='hospudka-pod-rablinu-aktuality'>Aktuálně</h1>
        <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>

        <Link to="/aktuality">
          <button className='hospudka-pod-rablinu-aktuality-tlacitko'>Přejít na aktuality</button>
        </Link>

      </div>
      
      <img src='./img/svatby.jpg' className='hospudka-pod-rablinu-pokoj-1'></img>
      <img src='./img/aktualni/exterior1.jpg' className='hospudka-pod-rablinu-pokoj-2'></img>

      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-5'></img>


      <h1 className='hospudka-pod-rablinu-aktuality'>Kde nás najdete</h1>
      <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>

      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6457.226696882322!2d17.769411!3d49.297946!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4713758a2bdaed6f%3A0x807b892635f2c5b8!2sHosp%C5%AFdka%20Pod%20Rablin%C5%AF!5e1!3m2!1scs!2sus!4v1732654509624!5m2!1scs!2sus" loading="lazy" className='mapa-1'></iframe>

      <br></br><br></br><p className='hospudka-pod-rablinu-zamluvte-si-termin-2'><b>GPS:</b> 49.297828, 17.768988</p>

      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-5'></img>
      

      <div className='hneda-footer'>

      <h1 className='hospudka-pod-rablinu-oteviraci-doba'>Otevírací doba</h1>

      <ul>
        <p className='oteviraci-doba-1'><b>Pondělí -</b> 11:00 - 20:00 (kuchyně do 19:00)</p>
        <p className='oteviraci-doba-2'><b>Úterý -</b>   11:00 - 20:00 (kuchyně do 19:00)</p>
        <p className='oteviraci-doba-2'><b>Středa -</b>  11:00 - 20:00 (kuchyně do 19:00)</p>
        <p className='oteviraci-doba-2'><b>Čtvrtek -</b> 11:00 - 20:00 (kuchyně do 19:00)</p>
        <p className='oteviraci-doba-2'><b>Pátek -</b>   11:00 - 21:00 (kuchyně do 20:00)</p>
        <p className='oteviraci-doba-2'><b>Sobota -</b>  11:00 - 21:00 (kuchyně do 20:00)</p>
        <p className='oteviraci-doba-2'><b>Neděle -</b>  11:00 - 20:00 (kuchyně do 19:00)</p>
      </ul>

      </div>

      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6'></img>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace'>Kontaktní informace</h1>
      <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Telefon</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>E-maily</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>info@hospudkapodrablinu.cz</h3>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>ubytovani@hospudkapodrablinu.cz</h3>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Sociální sítě</h1>


      <div className="socialni-site-odkazy">
        <a target='blank' href="https://www.instagram.com/hospudkapodrablinu/"><FaInstagram /></a>
        <a target='blank' href="https://www.facebook.com/hospudkapodrablinuofficial/?locale=cs_CZ"><FaFacebook /></a>
        <a target='blank' href="https://www.google.com/search?sca_esv=c59580ff1a3027c0&sxsrf=AHTn8zrfhOenBhPfCQ5ONOtlaw-12bhw9g:1740677070526&si=APYL9bvoDGWmsM6h2lfKzIb8LfQg_oNQyUOQgna9TyfQHAoqUn9TbiMDDkdCSgSBUOIgZRu152eAtKVlHvRyge69hmaMkw1Fq-XjaLEM_UVjIELoFZ7frZLmV711ZFurakw-wMnDCg5b6CXreY8umNlvdbn4BRoFbQ%3D%3D&q=Hosp%C5%AFdka+Pod+Rablin%C5%AF+Reviews&sa=X&ved=2ahUKEwiEh9Szr-SLAxWG-AIHHYB-ILgQ0bkNegQIHxAE&biw=1512&bih=795&dpr=2"><FaStar /></a>
        <a target='blank' href="https://www.google.com/search?q=hospudka+pod+rablinu&sca_esv=c59580ff1a3027c0&sxsrf=AHTn8zr3iqVLNVCKXp_aG8pQm7_RK1Wo4Q%3A1740676956791&ei=XJ_AZ_b-L8n6i-gP3_yN-AQ&hotel_occupancy=&ved=0ahUKEwj2nLb9ruSLAxVJ_QIHHV9-A08Q4dUDCBA&uact=5&oq=hospuddka+pod+arblonu&gs_lp=Egxnd3Mtd2l6LXNlcnAiFWhvc3B1ZGRrYSBwb2QgYXJibG9udTIEEAAYRzIEEAAYRzIEEAAYRzIEEAAYR0jiA1AAWABwAHgCkAEAmAEAoAEAqgEAuAEDyAEAmAIBoAIFmAMA4gMFEgExIECIBgGQBgSSBwExoAcA&sclient=gws-wiz-serp"><FaGooglePlus /></a>
      </div>


      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6'></img>

      <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>

      </div>

      </div>

{/* konec mobilni verze */}











{/* desktop sekce */}
    <div className='desktop-vypnuti'>


    <h3 className='desktop-homepage-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
    <h4 className='desktop-homepage-h4-main-nadpis'>Ta pravá chuť Valašska</h4>

    <h3 className='desktop-homepage-h3-main-telefon'>+420 608 608 101</h3>

      
   
    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>

    <div className='desktop-body'>

    <img className='desktop-obrazek-homepage1' src='./img/aktualni/hospudka-pod-rablinu.jpg'></img>

    <div className="stuha-desktop-container-1">
      <img className="desktop-obrazek-homepage1-stuha" src="./img/stuha-update.png" alt="Stuha"></img>
      <img className="desktop-obrazek-homepage1-stuha" src="./img/stuha-update.png" alt="Stuha"></img>
      <img className="desktop-obrazek-homepage1-stuha" src="./img/stuha-update.png" alt="Stuha"></img>
      <img className="desktop-obrazek-homepage1-stuha" src="./img/stuha-update.png" alt="Stuha"></img>
    </div>


    {/* <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /> */}

    <h1 className='desktop-homepage-h1-main-nadpis'>Vítejte v hospůdce pod Rablinů</h1>

    <p className='desktop-homepage-pod-nadpis-1'>
    <br></br>
    Náš penzion je zasazen do malebné přírody Hostýnských vrchů, kde na vás čeká pohostinná atmosféra a poctivá domácí kuchyně. <br /><br />
    V okolí naší hospůdky naleznete minizoo s domácími zvířaty a také dětské hřiště s lanovkou, v letních měsících doplněné navíc o skákací hrad. Kromě běžného provozu v restauraci a na terase zajišťujeme také oslavy narozenin, svatby, firemní akce a večírky.
    <br></br><br></br><br></br>

      <Link to="/aktuality"><b><u className='desktop-homepage-pod-nadpis-1-1'>Více informací najdete zde</u></b></Link>
    </p>


    <div className='desktop-objednavka-rezervace-1'>
      <p className='desktop-objednavka-rezervace-text-1'>Zamluvte si termín</p>
      <p className='desktop-objednavka-rezervace-text-2'>Na pár kliknutí</p>


      <Link to="/rezervace-ubytovani">
          <button className='desktop-hospudka-pod-rablinu-zamluvte-si-termin-tlacitko'>Rezervace ubytování</button>
      </Link>

      <Link to="/rezervace-akce">
          <button className='desktop-hospudka-pod-rablinu-zamluvte-si-termin-tlacitko-2'>Rezervace akcí</button>
      </Link>


    </div>

    <Link to="/aktuality">
      <img src="./img/hospudka-pod-rablinu-main-foto.jpg" className='desktop-obrazek-pod-rezervaci-1' alt="Hospudka pod Ranlinu okolni priroda" />
    </Link>

    <Link to="/ubytovani">
      <img src="./img/hlavni-interier.jpg" className='desktop-obrazek-pod-rezervaci-2' alt="Restaurace Lesna Zlin" />
    </Link>

    <Link to="/svatby">
      <img src="./img/fotka-interier-2.jpg" className='desktop-obrazek-pod-rezervaci-3' alt="Zlin Lesna ubytovani" />
    </Link>

    <Link to="/fotogalerie-pokoje" className='desktop-hpr-none-decoration-underline'>
      <p className='desktop-obrazek-pod-rezervaci-text'>Více z fotografií <FaChevronRight/></p>
    </Link>

    <div className='desktop-objednavka-rezervace-1'>

    <div className="stuha-desktop-container-2">
      <img className="desktop-obrazek-homepage1-stuha" src="./img/stuha-update.png" alt="Stuha"></img>
      <img className="desktop-obrazek-homepage1-stuha" src="./img/stuha-update.png" alt="Stuha"></img>
      <img className="desktop-obrazek-homepage1-stuha" src="./img/stuha-update.png" alt="Stuha"></img>
      <img className="desktop-obrazek-homepage1-stuha" src="./img/stuha-update.png" alt="Stuha"></img>
    </div>


      <p className='desktop-objednavka-rezervace-text-1'>Nezmeškejte žádné novinky</p>
      <p className='desktop-objednavka-rezervace-text-2'>Sledujte naše aktuality</p>


      <Link to="/aktuality">
          <button className='desktop-hospudka-pod-rablinu-zamluvte-si-termin-tlacitko'>Přejít na aktuality</button>
      </Link>

      <Link to="/galerie">
          <button className='desktop-hospudka-pod-rablinu-zamluvte-si-termin-tlacitko-2'>Fotografie</button>
      </Link>



    </div>


    <iframe src="https://frame.mapy.cz/s/gagogosuza" id='desktop-mapa' className='desktop-mapa-footer-1' loading="lazy"></iframe>




    
   


    </div>
     
    <Footer />
  </div>

    </div>
    
  );
}

export default HomePage;

import React from 'react';
// import "./desktop-galerie.css";
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";

// ikony

//styles DESKTOP -----------------------------------------------------------
import '../../styles/desktop/galerie/1920x1080.css'; //FULL HD
import '../../styles/desktop/galerie/1366x768px.css'; //DRUHE MONITORY
import '../../styles/desktop/galerie/2560x1440px.css'; //2K MONITORY
import { Link } from 'react-router-dom';



function Galerie() {
  return (
    <div>

{/* -------DESKTOP NAVBAR------------------------------------------------- */}


    <h3 className='desktop-homepage-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
    <h4 className='desktop-homepage-h4-main-nadpis'>Ta pravá chuť Valašska</h4>

    <h3 className='desktop-homepage-h3-main-telefon'>+420 608 608 101</h3>

    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>


{/* -------------------------------------------------------- */}



  <div className='desktop-aktuality-body'>
    <h3 className='desktop-aktuality-text-1'>FOTOGALERIE</h3>
  </div>


  <div className="desktop-galerie">

  <Link className='desktop-galerie-link-underline-none' to="/svatby-fotogalerie">
    <div className="desktop-galerie-image-container">
      <img src="./img/img-svatby.jpg" alt="Obrázek 1" />
      <div className="desktop-galerie-image-caption">SVATBY</div>
    </div>
  </Link>

  <Link className='desktop-galerie-link-underline-none' to="/sladky-bar-fotogalerie">
    <div className="desktop-galerie-image-container">
      <img src="./img/img-sladky-bar.jpg" alt="Obrázek 2" />
      <div className="desktop-galerie-image-caption">SLADKÝ BAR</div>
    </div>
  </Link>

  <Link className='desktop-galerie-link-underline-none' to="/fotogalerie-rauty">
    <div className="desktop-galerie-image-container">
      <img src="./img/img-rauty.jpg" alt="Obrázek 3" />
      <div className="desktop-galerie-image-caption">RAUTY</div>
    </div>
  </Link>

  <Link className='desktop-galerie-link-underline-none' to="/fotogalerie-akce-duo-jamaha">
    <div className="desktop-galerie-image-container">
      <img src="./img/img-akce-duo-jamaha.jpg" alt="Obrázek 4" />
      <div className="desktop-galerie-image-caption">AKCE DUO JAMAHA</div>
    </div>
  </Link>

</div>


{/* --------------------------- */}


<div className="desktop-galerie-2">

  <Link className='desktop-galerie-link-underline-none' to="/fotogalerie-speciality">
    <div className="desktop-galerie-image-container">
      <img src="./img/img-nase-speciality.jpg" alt="Obrázek 5" />
      <div className="desktop-galerie-image-caption">NAŠE SPECIALITY</div>
    </div>
  </Link>
  
  <Link className='desktop-galerie-link-underline-none' to="/fotogalerie-pokoje">
    <div className="desktop-galerie-image-container">
      <img src="./img/img-pokoje.jpg" alt="Obrázek 6" />
      <div className="desktop-galerie-image-caption">UBYTOVÁNÍ</div>
    </div>
  </Link>

  <Link className='desktop-galerie-link-underline-none' to="/fotogalerie-restaurace">
    <div className="desktop-galerie-image-container">
      <img src="./img/img-interier.jpg" alt="Obrázek 7" />
      <div className="desktop-galerie-image-caption">INTERIÉR</div>
    </div>
  </Link>

  <Link className='desktop-galerie-link-underline-none' to="/fotogalerie-exterier">
    <div className="desktop-galerie-image-container">
      <img src="./img/img-exterier.jpg" alt="Obrázek 8" />
      <div className="desktop-galerie-image-caption">EXTERIÉR</div>
    </div>
  </Link>

</div>
    
<div className='desktop-footer-galier-1'><Footer /></div>










    </div>
    
  );
}

export default Galerie;

import React from 'react';
import './mobile-jidelni-listek.css';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import MenuMobile from "../../komponenty/navbar-mobile/menu.js";
// ikony
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";

import 'animate.css';


//STYLES
import '../../styles/desktop/jidelni-listek/1366x768px.css';
import '../../styles/desktop/jidelni-listek/1440x900px.css';
import '../../styles/desktop/jidelni-listek/1920x1080.css';




function JidelniListek() {
  return (
    <div>
      
      {/* mobile sekce */}
      <div className='mobile-vypnuti'>

        <div id="menu-mobile-main">
          <MenuMobile />
        </div>

      {/* navbar */}
      <div id='cely-navbar' className='cely-navbar'>
        <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1'></img>

        <div className='navar-pozadi'>
          <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
          <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
        </div>

        <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2'></img>

        <div id="menu-mobile-externi">
          <MenuMobile />
        </div>

      </div>

{/* konec navbaru */}

<div className='jidelni-listek-mobile-pozadi-1'>
  <h1 className='jidelni-liskte-main-text-1'>JÍDELNÍ LÍSTEK</h1>


  <div className="jidelni-listek-container">
    <ul className="jidelni-listek-odkazy">
      <li><a href='#'>Polévky</a></li>
      <li><a href='#polevky'>Lehké menu</a></li>
      <li><a href='#lehke-menu'>Regionální speciality</a></li>
      <li><a href='#regionalni-speciality'>Saláty</a></li>
      <li><a href='#salaty'>Bezmasé menu</a></li>
      <li><a href='#bezmase-menu'>Sladké menu</a></li>
      <li><a href='#sladke-menu'>Šéfkuchař doporučuje</a></li>
      <li><a href='#sefkuchar-doporucuje'>Přílohy</a></li>
      <li><a href='#'>Pro malý hlad</a></li>
      <li><a href='#'>Omáčky</a></li>
    </ul>
  </div>


<h1 id="#"  className='jidelni-liskte-polevky-main'>Polévky</h1>
  <li className='jidelni-liskte-polevky-ul'>Valašská kyselice s pravými hříbky a klobásou</li>
  <li className='jidelni-liskte-polevky-ul-2'>Hovězí vývar s játrovými knedlíčky a nudlemi</li>


<h1 id='lehke-menu' className='jidelni-liskte-polevky-main'>Lehké menu</h1>
  <li className='jidelni-liskte-polevky-ul'>Filírováná kuřecí prsíčka</li>
  <i className='jidelni-liskte-polevky-ul-i'>(s bylinkovým pestem)</i>
  <li className='jidelni-liskte-polevky-ul-3'>Plněná kurečí prsíčka</li>
  <i className='jidelni-liskte-polevky-ul-i'>(s mozzarellou a sušenými rajčaty)</i>
  <li className='jidelni-liskte-polevky-ul-3'>Losos na grilu s bylinkovým pestem</li>


<h1 id='regionalni-speciality' className='jidelni-liskte-polevky-main'>Regionální speciality</h1>
  <li className='jidelni-liskte-polevky-ul'>Halušky s brynzou a domácím špekem</li>
  <li className='jidelni-liskte-polevky-ul-3'>Halušky se zelím a domácí slaninou</li>
  <li className='jidelni-liskte-polevky-ul-3'>Pomalu pečená medová žebírka na černém pivu</li>


<h1 id='salaty' className='jidelni-liskte-polevky-main'>Saláty</h1>
  <li className='jidelni-liskte-polevky-ul'>Caesar salát</li>
  <i className='jidelni-liskte-polevky-ul-i'>(filírované kuřecí prso se slaninou, krutony a parmezán)</i>
  <li className='jidelni-liskte-polevky-ul-3'>Šopský salát s balkánským sýrem</li>
  <li className='jidelni-liskte-polevky-ul-3'>Zeleninový salát s grilovaným hermelínem</li>


<h1 id='bezmase-menu' className='jidelni-liskte-polevky-main'>Bezmasé menu</h1>
  <li className='jidelni-liskte-polevky-ul'>Smažený hermelín</li>
  <li className='jidelni-liskte-polevky-ul-3'>Smažený eidam</li>


<h1 id='sladke-menu' className='jidelni-liskte-polevky-main'>Sladké menu</h1>
  <li className='jidelni-liskte-polevky-ul'>Valašský knedlík</li>
  <i className='jidelni-liskte-polevky-ul-i'>(Borůvky se zakysanou smetanou a borůvkovým dipem)</i>

  <li className='jidelni-liskte-polevky-ul-3'>Domácí palačinky</li>
  <i className='jidelni-liskte-polevky-ul-i'>(s domácí borůvkovou marmeládou, máslem a šlehačkou)</i>

  <li className='jidelni-liskte-polevky-ul-3'>Palačinky Paní nadlesní</li>
  <i className='jidelni-liskte-polevky-ul-i'>(perník, oříšky, med, cukr, máslo a skořice)</i>



<h1 id='sefkuchar-doporucuje' className='jidelni-liskte-polevky-main'>Šéfkuchař doporučuje</h1>
  <li className='jidelni-liskte-polevky-ul'>Velký Vídeňský řízek </li>
  <i className='jidelni-liskte-polevky-ul-i'>(z vepřové panenky pomalu smažený na sádle)</i>

  <li className='jidelni-liskte-polevky-ul-3'>Málý Vídeňský řízek</li>
  <i className='jidelni-liskte-polevky-ul-i'>(z vepřové panenky pomalu smažený na sádle)</i>

  <li className='jidelni-liskte-polevky-ul-3'>Smažená kuřecí prsíčka</li>

  <li className='jidelni-liskte-polevky-ul-3'>Myslivecký guláš na domácím špeku s cibulí </li>
  <i className='jidelni-liskte-polevky-ul-i'>(chléb/variace knedlíků)</i>

  <li className='jidelni-liskte-polevky-ul-3'>Svíčková na smetaně</li>
  <i className='jidelni-liskte-polevky-ul-i'>(z oříšku mladého býčka, variace knedlíků)</i>

  <li className='jidelni-liskte-polevky-ul-3'>Špalíček z vepřové panenky</li>

  <li className='jidelni-liskte-polevky-ul-3'>Steak z mladého býčka z Kašavského údolí <b>(BIO)</b></li>

  <li className='jidelni-liskte-polevky-ul-3'>Grilovaný prsní kuřecí steak</li>

  <li className='jidelni-liskte-polevky-ul-3'>Smažená kuřecí prsíčka plněná tvarůžky a slaninou</li>

  <li className='jidelni-liskte-polevky-ul-3'>Konfitované kachní stehno, červené zelí s perníkem, skořicí, jablíčkem a medem, variace knedlíků</li>

  

  <div className="jidelni-lisktek-obrazek-container-1">
    <img src='./img/jidelni-listek/salat.jpg' className='jidelni-lisktek-obrazek-1' />
    <img src='./img/jidelni-listek/kolacky-1.jpg' className='jidelni-lisktek-obrazek-2' />
    <img src='./img/jidelni-listek/maso-1.jpg' className='jidelni-lisktek-obrazek-3' />
  </div>

  <div className="jidelni-lisktek-obrazek-container-2">
    <img src='./img/jidelni-listek/bagety-1.jpg' className='jidelni-lisktek-obrazek-1' />
    <img src='./img/jidelni-listek/maso-stul-1.jpg' className='jidelni-lisktek-obrazek-2' />
    <img src='./img/jidelni-listek/maso-2.jpg' className='jidelni-lisktek-obrazek-3' />
  </div>

  <img src='./img/stuha2.png' className='jidelni-listek-stuha-1'></img>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace'>Kontaktní informace</h1>
      <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Telefon</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>E-maily</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>info@hospudkapodrablinu.cz</h3>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>ubytovani@hospudkapodrablinu.cz</h3>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Sociální sítě</h1>


      <div className="socialni-site-odkazy">
        <a target='blank' href="#"><FaInstagram /></a>
        <a target='blank' href="#"><FaFacebook /></a>
        <a target='blank' href="#"><FaYoutube /></a>
        <a target='blank' href="#"><FaGooglePlus /></a>
      </div>


      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6'></img>

      <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>

  </div> 

</div>



{/* -------------------------------------------------------------------------------- */}




<div className='desktop-vypnuti'>

<h3 className='desktop-aktuality-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
    <h4 className='desktop-aktuality-h4-main-nadpis'>Ta pravá chuť Valašska</h4>

    <h3 className='desktop-aktuality-h3-main-telefon'>+420 608 608 101</h3>

      
   
    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>

{/* -------------------------------------------------------- */}

  <div className='desktop-aktuality-body'>

    <h3 className='desktop-aktuality-text-1'>JÍDELNÍ LÍSTEK</h3>

  </div>





  <div className="desktop-menu-jidelni-listek">
        <div className="desktop-section-jidelni-listek">
            <h2 className="desktop-title-jidelni-listek">Polévky</h2>
            <p>0,5l Valašská kyselice s pravými hříbky a klobásou</p>
            <p>0,5l Hovězí vývar s játrovými knedlíčky a nudlemi</p>
        </div>

        <div className="desktop-section-jidelni-listek">
            <h2 className="desktop-title-jidelni-listek">Regionální speciality</h2>
            <p>300g Halušky s brynzou a domácím špekem</p>
            <p>300g Halušky se zelím a slaninou</p>
            <p>400g Pomalu pečená medová žebírka na černém pivu a chléb</p>
        </div>

        <div className="desktop-section-jidelni-listek">
            <h2 className="desktop-title-jidelni-listek">Bezmasé menu</h2>
            <p>100g Smažený hermelín</p>
            <p>120g Smažený eidam</p>
        </div>

        <div className="desktop-section-jidelni-listek">
            <h2 className="desktop-title-jidelni-listek">Lehké menu</h2>
            <p>200g Filírovaná kuřecí prsíčka s bylinkovým pestem</p>
            <p>180g Kuřecí prsíčka plněná mozzarellou a sušenými rajčaty</p>
            <p>150g Losos na grilu s bylinkovým pestem</p>
        </div>

        <div className="desktop-section-jidelni-listek">
            <h2 className="desktop-title-jidelni-listek">Saláty</h2>
            <p>300g Caesar salát (filírované kuřecí prso se slaninou, krutony, parmezán)</p>
            <p>300g Šopský salát s balkánským sýrem</p>
            <p>300g Zeleninový salát s grilovaným hermelínem a dijonským přelivem</p>
        </div>

        <div className="desktop-section-jidelni-listek">
            <h2 className="desktop-title-jidelni-listek">Sladké menu</h2>
            <p>450g Valašský knedlík (plněný borůvkami se zakysanou smetanou a borůvkovým dipem)</p>
            <p>2ks Palačinky s domácí borůvkovou marmeládou, máslem a šlehačkou</p>
            <p>2ks Palačinky Paní nadlesní (perník, oříšky, med, cukr, máslo a skořice)</p>
        </div>

        <div className="desktop-section-jidelni-listek">
            <h2 className="desktop-title-jidelni-listek">Šéfkuchař doporučuje</h2>
            <p>400g Velký Vídeňský řízek (z vepřové panenky pomalu smažený na sádle)</p>
            <p>200g Malý Vídeňský řízek (z vepřové panenky pomalu smažený na sádle)</p>
            <p>150g Smažená kuřecí prsíčka</p>
            <p>150g Myslivecký guláš na domácím špeku s cibulí (chléb/variace knedlíků)</p>
            <p>150g Svíčková na smetaně (z oříšku mladého býčka, variace knedlíků)</p>
            <p>200g Špalíček z vepřové panenky</p>
            <p>200g Steak z mladého býčka z Kašavského údolí BIO</p>
            <p>200g Grilovaný prsní kuřecí steak</p>
            <p>180g Smažená kuřecí prsíčka plněná tvarůžky a domácí slaninou</p>
            <p>300g Konfitované kachní stehno, červené zelí s perníkem, skořicí, jablíčkem a medem, variace knedlíků</p>
        </div>

        <div className="desktop-section-jidelni-listek">
            <h2 className="desktop-title-jidelni-listek">Omáčky ke steakům</h2>
            <p>0,1l Houbová omáčka</p>
            <p>0,1l Pepřová</p>
            <p>Kečup</p>
            <p>Tatarská omáčka</p>
        </div>

        <div className="desktop-section-jidelni-listek">
            <h2 className="desktop-title-jidelni-listek">Přílohy</h2>
            <p>150g Hranolky</p>
            <p>200g Šťouchané brambory</p>
            <p>200g Vařené brambory</p>
            <p>200g Rozmarýnové brambory</p>
            <p>250g Grilovaná zelenina</p>
            <p>150g Restované fazolové lusky se slaninou</p>
            <p>200g Bramborový salát</p>
        </div>

        <div className="desktop-section-jidelni-listek">
            <h2 className="desktop-title-jidelni-listek">Pro malý hlad</h2>
            <p>70g Zvěřinová Paštika, brusinky a pečivo</p>
            <p>220g Domácí libová tlačenka v patentní sklenici</p>
            <p>120g Tatarák z mladého býčka z Kašavského údolí BIO (4ks topinky)</p>
        </div>
    </div>


<div className='desktop-jidelni-listek-footer'><Footer /></div>









  </div>          {/* konec DESKTOP verze */}
</div>
);
}

export default JidelniListek;

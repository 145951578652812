import React, { useState, useEffect, useContext, useRef } from 'react';
import './mobile-fotogalerie-duo-jamaha-desktop.css';
import './mobile-fotogalerie-duo-jamaha-mobile.css';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


import MenuMobile from "../../komponenty/navbar-mobile/menu.js";
// ikony
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";

import 'animate.css';



function MobileFotogalerieNaseSpeciality() {
  return (
    <div>

{/* mobile sekce */}
      <div className='mobile-vypnuti'>

          <div id="menu-mobile-main">
            <MenuMobile />
          </div>

{/* navbar */}
        <div id='cely-navbar' className='cely-navbar'>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1'></img>

          <div className='navar-pozadi'>
            <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
            <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
          </div>
      
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2'></img>

          <div id="menu-mobile-externi">
            <MenuMobile />
          </div>

        </div>
{/* konec navbaru */}

      
<div className='fotogalerie-mobile-pozadi-2'>
  <h1 className='jidelni-liskte-main-text-1'>NAŠE SPECIALITY</h1>


  <div className="jidelni-listek-container">
    <ul className="jidelni-listek-odkazy">
      <li><a href='#'>Svatby</a></li>
      <li><a href='#polevky'>Ubytování</a></li>
      <li><a href='#lehke-menu'>Restaurace & menu</a></li>
      <li><a href='#regionalni-speciality'>Akce</a></li>

      <li><a href='#salaty'>Exteriér a okolí</a></li>
      <li><a href='#bezmase-menu'>Naše speciality</a></li>
      <li><a href='#sladke-menu'>Sladký bar</a></li>
      <li><a href='#sefkuchar-doporucuje'>Rauty</a></li>
      <li><a href='#sefkuchar-doporucuje'>Duo Jamaha</a></li>

    </ul>
  </div>


  <img className='fotogalerie-svatby-main-fotka-1' src='./img/speciality/speciality-1.jpg'></img>
  <img className='svatby-main-fotka-2' src='./img/speciality/speciality-2.jpg'></img>
  <img className='svatby-main-fotka-3' src='./img/speciality/speciality-3.jpg'></img>

  {/* <p className='fotogalerie-svatby-text-pod-img'><b>Březen, </b> 2024</p> */}

{/*  ------------------------------------------------------------------------------ */}

  <img className='fotogalerie-svatby-main-fotka-1' src='./img/speciality/speciality-4.jpg'></img>
  <img className='fotogalerie-svatby-main-fotka-2' src='./img/speciality/speciality-5.jpg'></img>
  <img className='fotogalerie-svatby-main-fotka-2' src='./img/speciality/speciality-6.jpg'></img>


  {/* <p className='fotogalerie-svatby-text-pod-img'><b>Červenec, </b> 2024</p> */}

{/*  ------------------------------------------------------------------------------ */}

  <img className='fotogalerie-svatby-main-fotka-1' src='./img/speciality/speciality-7.jpg'></img>
  <img className='fotogalerie-svatby-main-fotka-2' src='./img/speciality/speciality-8.jpg'></img>
  <img className='svatby-main-fotka-2' src='./img/speciality/speciality-9.jpg'></img>
  <img className='svatby-main-fotka-3' src='./img/speciality/speciality-12.jpg'></img>

  {/* <p className='fotogalerie-svatby-text-pod-img'><b>Březen, </b> 2024</p> */}

{/*  ------------------------------------------------------------------------------ */}

  <img className='fotogalerie-svatby-main-fotka-1' src='./img/speciality/speciality-5.jpg'></img>
  <img className='svatby-main-fotka-2' src='./img/speciality/speciality-2.jpg'></img>
  <img className='svatby-main-fotka-3' src='./img/speciality/speciality-6.jpg'></img>

  {/* <p className='fotogalerie-svatby-text-pod-img'><b>Březen, </b> 2024</p> */}

{/*  ------------------------------------------------------------------------------ */}


<img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1'></img>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace'>Kontaktní informace</h1>
      <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Telefon</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>E-maily</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>info@hospudkapodrablinu.cz</h3>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>ubytovani@hospudkapodrablinu.cz</h3>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Sociální sítě</h1>


      <div className="socialni-site-odkazy">
        <a target='blank' href="#"><FaInstagram /></a>
        <a target='blank' href="#"><FaFacebook /></a>
        <a target='blank' href="#"><FaYoutube /></a>
        <a target='blank' href="#"><FaGooglePlus /></a>
      </div>


      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6'></img>

      <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>

    
  </div>



      </div>
    </div>
    
  );
}

export default MobileFotogalerieNaseSpeciality;

import React, { useState, useEffect, useContext, useRef } from 'react';
import NavbarDesktop from "../navbar/navbar-desktop.tsx";
import Footer from "../footer/footer.tsx";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


import MenuMobile from "../../komponenty/navbar-mobile/menu.js";
// ikony
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";

import 'animate.css';


//styles DESKTOP -----------------------------------------------------------
import '../../styles/desktop/aktuality/1920x1080.css'; //FULL HD
import '../../styles/desktop/aktuality/1366x768px.css'; //DRUHE MONITORY
import '../../styles/desktop/aktuality/2560x1440px.css'; //2K MONITORY


//styles TELEFON -----------------------------------------------------------
import '../../styles/mobile/aktuality/360x640px.css'; //*TELEFONY


function Aktuality() {
  return (
    <div>

{/* mobile sekce */}
      <div className='mobile-vypnuti'>

          <div id="menu-mobile-main">
            <MenuMobile />
          </div>

{/* navbar */}
<div id='cely-navbar' className='cely-navbar'>
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-1'></img>

          <div className='navar-pozadi'>
            <h1 className='hospudka-pod-rablinu-nazev-1 animate__animated animate__backInLeft'>Hospůdka pod Rablinů</h1>
            <h3 className='hospudka-pod-rablinu-nazev-2 animate__animated animate__backInLeft'>Ta pravá chuť Valašska</h3>
          </div>
      
          <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-2'></img>

          <div id="menu-mobile-externi">
            <MenuMobile />
          </div>
  </div>
{/* konec navbaru */}


<h1 className='mobile-aktuality-text-main animate__animated animate__backInLeft animate__delay-0.5s'>🪺 Velikonoční pobyt 🐣</h1>
  
<h4 className='mobile-aktuality-text-second animate__animated animate__backInLeft animate__delay-0.5s'>
Přijďte si užít Velikonoce v srdci přírody!🏡 <br /><br />Čeká na Vás animační program pro děti, mini zoo a spousta zábavy. Pro dospělé pak speciální velikonoční menu plné tradičních chutí. 🍽️
</h4>  
      
<div className='aktuality-mobile-pozadi-1'>
  <img src='./img/aktualni/aktuality-main-obrazek.jpg' className='aktuality-changing-image-1 animate__animated animate__backInLeft animate__delay-1s'></img>
</div>



{/*  ------------------------------------------------------------------------------ */}


<img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-fotogalerie-1'></img>

      <h1 className='hospudka-pod-rablinu-kontaktni-informace'>Kontaktní informace</h1>
      <img src='./img/obrazek-hpr-2.PNG' className='hospudka-pod-rablinu-logo-3'></img>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Telefon</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-3'>+420 608 608 101</h3>


      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>E-maily</h1>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-4'>info@hospudkapodrablinu.cz</h3>
      <h3 className='hospudka-pod-rablinu-kontaktni-informace-5'>ubytovani@hospudkapodrablinu.cz</h3>
      
      <h1 className='hospudka-pod-rablinu-kontaktni-informace-2'>Sociální sítě</h1>


      <div className="socialni-site-odkazy">
        <a target='blank' href="#"><FaInstagram /></a>
        <a target='blank' href="#"><FaFacebook /></a>
        <a target='blank' href="#"><FaYoutube /></a>
        <a target='blank' href="#"><FaGooglePlus /></a>
      </div>


      <img src='./img/stuha2.png' className='hospudka-pod-rablinu-stuha-6'></img>

      <h3 className='chraneno-autorskými-právy'>© 2020 - 2025 GAMPEIROS s.r.o.</h3>

    
  </div>











{/* ------------------------------DESKTOP--------------------------- */}



<div className='desktop-vypnuti'>

<h3 className='desktop-aktuality-h3-main-nadpis'>Hospůdka pod Rablinů</h3>
    <h4 className='desktop-aktuality-h4-main-nadpis'>Ta pravá chuť Valašska</h4>

    <h3 className='desktop-aktuality-h3-main-telefon'>+420 608 608 101</h3>

      
   
    <div className='desktop-navbar'>
        <NavbarDesktop />
    </div>


{/* KONEC NAVBARU --- KONEC NAVBARU --- KONEC NAVBARU --- KONEC NAVBARU --- KONEC NAVBARU --- KONEC NAVBARU*/}


<div className='desktop-aktuality-body'>
    <h3 className='desktop-aktuality-text-1'>AKTUALITY</h3>
  </div>

  <h1 className='desktop-aktuality-text-main'>🪺 Velikonoční pobyt 🐣</h1>           {/* CMS */}
  <h4 className='desktop-aktuality-text-second'>                                      {/* CMS */}
  Přijďte si užít Velikonoce v srdci přírody!🏡 Čeká na Vás animační program pro děti, mini zoo a spousta zábavy. Pro dospělé pak speciální velikonoční menu plné tradičních chutí. 🍽️
    </h4>         

    <p className='desktop-aktuality-datum-akce'><b>Datum akce : </b> 17.4.2025 - 21.4.2025</p>
  <img src='./img/aktualni/aktuality-main-obrazek.jpg' className='desktop-aktuality-img-main'></img>

  <div className='desktop-aktuality-footer'><Footer /></div>





</div>


</div>    
  );
}

export default Aktuality;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; //smazano link

import HomePage from "./komponenty/homepage/homepage.tsx";
import Aktuality from './komponenty/aktuality/aktuality.tsx';
import Galerie from "./komponenty/galerie/galerie.tsx";
import Kontakt from "./komponenty/kontakt/kontakt.tsx";
import Firmy from "./komponenty/firmy/firmy.tsx";
import DenniNabidka from "./komponenty/denni_nabidka/denni-nabidka.tsx";
import JidelniListek from "./komponenty/jidelni_listek/jidelni_listek.tsx";
import OdpoledniNabidka from "./komponenty/odpoledni_nabidka/odpoledni_nabidka.tsx"
import NapojovyListek from "./komponenty/napojovy_listek/napojovy_listek.tsx"
import Svatby from "./komponenty/svatby/svatby.tsx";
import Fotogalerie from "./komponenty/fotogalerie/fotogalerie.tsx";
import Fotogalerie_svatby from "./komponenty/fotogalerie/fotogalerie_svatby/fotogalerie_svatby.tsx";
import Ubytovani from "./komponenty/ubytovani/ubytovani.tsx";
import Rezervace from "./komponenty/rezervace/rezervace.tsx";
import RezervaceUbytovani from "./komponenty/rezervace-ubytovani/rezervace-ubytovani.tsx";
import RezervaceAkce from "./komponenty/rezervace-akce/rezervace-akce.tsx";
import Balicky from "./komponenty/balicky/balicky.tsx";


import ScriptNovaPageOnTop from "./scripty/scriptNovaPageOnTop.tsx";

import SvatbyFotogalerie from './komponenty/svatby-fotogalerie/svatby-fotogalerie.tsx';
import SladkyBarFotogalerie from "./komponenty/sladky-bar-fotogalerie/sladky-bar-fotogalerie.tsx";
import FotogalerieRauty from "./komponenty/fotogalerie-rauty/fotogalerie-rauty.tsx";
import FotogalerieAkceDuoJamaha from "./komponenty/fotogalerie-akce-duo-jamaha/fotogalerie-akce-duo-jamaha.tsx";
import FotogalerieSpeciality from './komponenty/fotogalerie-speciality/fotogalerie-speciality.tsx';
import FotogaleriePokoje from './komponenty/fotogalerie-pokoje/fotogalerie-pokoje.tsx';
import FotogalerieRestaurace from './komponenty/fotogalerie-restaurace/fotogalerie-restaurace.tsx';
import FotogalerieExterier from './komponenty/fotogalerie-exterier/fotogalerie-exterier.tsx';


//MOBILE FOTOGALERIE
import MobileFotogalerieUbytovani from './komponenty/mobile-fotogalerie-ubytovani/mobile-fotogalerie-ubytovani.tsx';
import MobileFotogalerieRestaurace from './komponenty/mobile-fotogalerie-restaurace/mobile-fotogalerie-restaurace.tsx';


//SVATBY
import SvatbyProstory from './komponenty/svatby-prostory/svatby-prostory.tsx';
import SvatbyHostina from './komponenty/svatby-hostina/svatby-hostina.tsx';
import MobileFotogalerieDuoJamaha from './komponenty/mobile-fotogalerie-duo-jamaha/mobile-fotogalerie-duo-jamaha.tsx';
import MobileFotogalerieExterier from './komponenty/mobile-fotogalerie-exterier/mobile-fotogalerie-exterier.tsx';
import MobileFotogalerieNaseSpeciality from './komponenty/mobile-fotogalerie-nase-speciality/mobile-fotogalerie-nase-speciality.tsx';
import MobileFotogalerieSladkyBar from './komponenty/mobile-fotogalerie-sladky-bar/mobile-fotogalerie-rauty.tsx';
import MobileFotogalerieRauty from "./komponenty/mobile-fotogalerie-rauty/mobile-fotogalerie-sladky-bar.tsx";




function App() {
  return (
    <Router>
      <ScriptNovaPageOnTop />
      <div>
        

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/aktuality" element={<Aktuality />} />
          <Route path="/galerie" element={<Galerie />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/firmy" element={<Firmy />} />
          <Route path="/svatby" element={<Svatby />} />
          <Route path="/ubytovani" element={<Ubytovani />} />
          <Route path="/fotogalerie" element={<Fotogalerie />} />
          <Route path="/rezervace" element={<Rezervace />} />
          <Route path="/rezervace-ubytovani" element={<RezervaceUbytovani />} />
          <Route path="/rezervace-akce" element={<RezervaceAkce />} />


{/* fotogalerie */}
          <Route path="/fotogalerie_svatby" element={<Fotogalerie_svatby />} />
          <Route path="/mobile-fotogalerie-ubytovani" element={<MobileFotogalerieUbytovani />} />
          <Route path="/mobile-fotogalerie-restaurace" element={<MobileFotogalerieRestaurace />} />
          <Route path="/mobile-fotogalerie-duo-jamaha" element={<MobileFotogalerieDuoJamaha />} />
          <Route path="/mobile-fotogalerie-exterier" element={<MobileFotogalerieExterier />} />
          <Route path="/mobile-fotogalerie-nase-speciality" element={<MobileFotogalerieNaseSpeciality />} />
          <Route path="/mobile-fotogalerie-sladky-bar" element={<MobileFotogalerieSladkyBar />} />
          <Route path="/mobile-fotogalerie-raut" element={<MobileFotogalerieRauty />} />



          {/* MENU */}
          <Route path="/denni-nabidka" element={<DenniNabidka />} />
          <Route path="/jidelni-listek" element={<JidelniListek />} />
          <Route path="/odpoledni-nabidka" element={<OdpoledniNabidka />} />
          <Route path="/napojovy-listek" element={<NapojovyListek />} />
          
          <Route path="/ubytovani" element={<Ubytovani />} />
          <Route path="/balicky" element={<Balicky />} />
          <Route path="/svatby-prostory" element={<SvatbyProstory />} />
          <Route path="/svatby-hostina" element={<SvatbyHostina />} />



          {/* FOTOGALERIE DESKTOP */}
          <Route path="/svatby-fotogalerie" element={<SvatbyFotogalerie />} />
          <Route path="/sladky-bar-fotogalerie" element={<SladkyBarFotogalerie />} />
          <Route path="/fotogalerie-rauty" element={<FotogalerieRauty />} />
          <Route path="/fotogalerie-akce-duo-jamaha" element={<FotogalerieAkceDuoJamaha />} />
          <Route path="/fotogalerie-speciality" element={<FotogalerieSpeciality />} />
          <Route path="/fotogalerie-pokoje" element={<FotogaleriePokoje />} />
          <Route path="/fotogalerie-restaurace" element={<FotogalerieRestaurace />} />
          <Route path="/fotogalerie-exterier" element={<FotogalerieExterier />} />

          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
